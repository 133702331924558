<template>
    <div class="colapsables">
        <div   class="edicion_block" >
            <div  @click="show.s2=!show.s2" class="navcola manito" :style="cardVal.isLanding?'border-top:0px !important;':''">
                <h4>{{$t('bc.settings')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s2?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s2" v-show-slide="show.s2">
                <div class="form-group">
                    <label> {{$t('info.nombreland')}}</label>
                    <input class="form-control" type="text"  v-validate="{ required: true }" name="nombre" maxlength="50" :placeholder="$t('info.nombreland')" v-model='cardVal.info.nombre_landing'>
                    <client-only><span class="vuerror">{{ errors.first('nombre') }}</span></client-only>
                </div>
                <div class="form-group">
                    <span class="info_text">{{$t('micuenta.idioma')}} <small>{{$t('info.langsub')}}</small></span>
                    <multiselect 
                    :allow-empty="false"
                    v-model="mylang" 
                    :options="langs.map(type => type.id)" 
                    :custom-label="opt => langs.find(x => x.id == opt).name"
                    :placeholder="$t('general.select2')"
                    deselectLabel=""
                    selectLabel=""/>
                </div>
                <div class="form-group">
                    <span class="info_text">{{$t('info.folder')}}</span>
                    <multiselect 
                        :allow-empty="false"
                        v-model="sector"
                        :options="getUser.sectors.map(type => type.id)" 
                        :custom-label="opt => getUser.sectors.find(x => x.id == opt).name"
                        :placeholder="$t('general.select2')"
                        deselectLabel=""
                        selectLabel=""/>
                </div>
                <div v-if="!cardVal.isLanding" class="form-group">
                    <span class="info_text">{{$t('info.tabs')}}</span>
                    <div class="order_contenedor  d-flex align-items-center justify-content-between">
                        <span>{{$t('bc.about')}}</span>
                        <div class="d-flex align-items-center justify-content-between" >
                            <switchPicker  class="switchet" v-model='sobre_active'/> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="edicion_block" >
            <div @click="show.s1=!show.s1"  class="navcola manito" >
                <h4>{{$t('general.seo')}}</h4><!--<font-awesome-icon v-if="!$util.isTrialOrPro()" :icon="['fas','lock']" />-->
                <font-awesome-icon :icon="['fas','chevron-'+(show.s1?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s1" v-show-slide="show.s1">
                <imagePicker :btext="$t('pickers.loadi')" imgid="profile" v-model="seo.img"   />
                <span class="info_text">* {{$t('settings.featimsub')}}</span>
                <img v-if="seo.img && seo.img.obj" :src="seo.img.obj" width="50%"/>
                <img v-else-if="seo.img" :src="seo.img" width="50%"/>
                <div class="form-group mt20">
                    <input class="form-control" type="text" maxlength="50" :placeholder="$t('drags.titulo')" v-model="seo.title">
                </div>
                <div class="form-group mt20">
                    <textarea maxlength="400"  rows="7" type="text" :placeholder="$t('drags.texto')" v-model="seo.desc"  style="width:100% !important;border:0px;overflow:hidden;" ></textarea>
                </div>
            </div>
        </div><!--
        <div v-if="!cardVal.isLanding" class="edicion_block">
            <div  @click="show.s10=!show.s10" class="navcola  ">
                <h4>{{$t('info.tabs')}}</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s10?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s10" v-show-slide="show.s10">
                    <span class="info_text">{{$t('info.langsub')}}</span>
                <div class="order_contenedor  d-flex align-items-center justify-content-between">
                    <span>{{$t('bc.about')}}</span>
                    <div class="d-flex align-items-center justify-content-between" >
                        <switchPicker  class="switchet" v-model='sobre_active'/> 
                    </div>
                </div>
            </div>
        </div>
        <div v-if="getUser && getUser.sectors && !$util.isLoyaltyCustomizer() && $util.showSectors(cardVal.isLanding)" class="edicion_block" >
            <div  @click="show.s7=!show.s7" class="navcola manito" >
                    <h4>{{$t('info.folder')}}</h4>
                    <font-awesome-icon :icon="['fas','chevron-'+(show.s7?'up':'down')]"/>
                </div>
                <div :class="'contenido '+show.s7" v-show-slide="show.s7">
                    <div class="form-group">
                        <multiselect 
                            :allow-empty="false"
                            v-model="sector"
                            :options="getUser.sectors.map(type => type.id)" 
                            :custom-label="opt => getUser.sectors.find(x => x.id == opt).name"
                            :placeholder="$t('general.select2')"
                            deselectLabel=""
                            selectLabel=""/>
                    </div>
            </div>
        </div>-->
        <div v-if="cardVal.isLanding" class="edicion_block">
            <div   class="navcola manito">
                <h4 style="display:inline;" @click="show.s11=!show.s11" >Business Card + Signature</h4>  <font-awesome-icon @click="$modal.show('modal-videotour',{type:'signature'})" :icon="['fas','info-circle']"/>
                <font-awesome-icon @click="show.s11=!show.s11" :icon="['fas','chevron-'+(show.s11?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s11" v-show-slide="show.s11">
                <div class="form-group">
                    <imagePicker :btext="$t('pickers.loadi')" imgid="profile" v-model="preview.img" :isCircle="true" :isCrop="true" :aspect="1"  /> <small>(*{{$t('general.requerido')}})</small>
                    
                </div>
                <div class="form-group">
                    <input class="form-control" type="text"  v-validate="{ required: true }" name="nombre" maxlength="50" :placeholder="$util.isLoyaltyCustomizer()?$t('loyaltys.nombrecc'):$t('info.nombrecc')" v-model='preview.nombre'> <small>(*{{$t('general.requerido')}})</small>
                    <client-only><span class="vuerror">{{ errors.first('nombre') }}</span></client-only>
                </div>
                <div class="form-group">
                    <input class="form-control" type="text"  v-validate="{ required: true}" name="trabajo" maxlength="50" :placeholder="$util.isLoyaltyCustomizer()?$t('loyaltys.profes'):$t('info.profes')"  v-model='preview.trabajo'> <small>(*{{$t('general.requerido')}})</small>
                    <client-only><span class="vuerror">{{ errors.first('trabajo') }}</span></client-only> 
                </div>
                <div class="form-group">
                        <vue-phone-number-input
                        v-model="mobilephone"
                        :valid-color="'none'"
                        :default-country-code="preview.mobilephone_code"
                        :placeholder="$t('info.telmovil')"
                        @update="onUpdateMobile"
                        :translations="$t('inputphone_mobile')" />
                </div>

                <div v-if="getUser.email=='samantha.bowins@salientmed.com'" class="form-group">
                    <input class="form-control" type="text"  v-validate="{ required: true}" name="trabajo" maxlength="50" :placeholder="$util.isLoyaltyCustomizer()?$t('loyaltys.profes'):$t('info.profes')"  v-model='preview.phone'> <small>(*{{$t('general.requerido')}})</small>
                    <client-only><span class="vuerror">{{ errors.first('trabajo') }}</span></client-only> 
                </div>
                <div v-else class="form-group">
                        <vue-phone-number-input
                        v-model="fixedphone"
                        :valid-color="'none'"
                        :default-country-code="preview.phone_code"
                        :no-validator-state="false"
                        :placeholder="$t('info.telfijo')"
                        @update="onUpdateFixed" 
                        :translations="$t('inputphone_work')" />
                </div>
                <div class="form-group">
                        <input class="form-control" autocomplete="off"  v-validate="'required|email'" name="email" type="text" maxlength="100"  :placeholder="$t('info.correoelec')" v-model='preview.email' >
                        <client-only><span class="vuerror">{{ errors.first('email') }}</span></client-only>
                </div>
                <div  class="form-group">
                        <input class="form-control"  name="url" type="text" maxlength="200" :placeholder="$t('bc.calendurl')" v-model='preview.calendar'>
                </div>
                <div  class="form-group">
                        <input class="form-control"  name="address" type="text" maxlength="200" :placeholder="$t('bc.address')" v-model='preview.address'>
                </div>
                <div  class="form-group">
                    <input class="form-control"  name="city" type="text" maxlength="200" :placeholder="$t('bc.city')" v-model='preview.city'>
                </div>
                <div  class="form-group">
                    <input class="form-control"  name="city" type="text" maxlength="200" :placeholder="$t('bc.state')" v-model='preview.state'>
                </div>
                <div  class="form-group">
                    <input class="form-control"  name="country" type="text" maxlength="200" :placeholder="$t('bc.country')" v-model='preview.country'>
                </div>
                <div  class="form-group">
                    <input class="form-control"  name="postal_code" type="text" maxlength="200" :placeholder="$t('bc.postal_code')" v-model='preview.postal_code'>
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_website"  name="website"  type="text" maxlength="200" placeholder="Website" v-model='preview.website'>
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_facebook" type="text" maxlength="100" placeholder="Facebook" v-model='preview.facebook' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_instagram" type="text" maxlength="100" placeholder="Instagram" v-model='preview.instagram' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_twitter" type="text" maxlength="100" placeholder="Twitter" v-model='preview.twitter' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_linkedin" type="text" maxlength="100" placeholder="LinkedIn" v-model='preview.linkedin' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_youtube" type="text" maxlength="100" placeholder="Youtube" v-model='preview.youtube' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_vimeo" type="text" maxlength="100" placeholder="Vimeo" v-model='preview.vimeo' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_skype" type="text" maxlength="100" placeholder="Skype" v-model='preview.skype' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_behance" type="text" maxlength="100" placeholder="Behance" v-model='preview.behance' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_dribbble" type="text" maxlength="100" placeholder="Dribble" v-model='preview.dribbble' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_github" type="text" maxlength="100" placeholder="Github" v-model='preview.github' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_tiktok" type="text" maxlength="100" placeholder="Tiktok" v-model='preview.tiktok' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_spotify" type="text" maxlength="100" placeholder="Spotify" v-model='preview.spotify' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_snapchat" type="text" maxlength="100" placeholder="Snapchat" v-model='preview.snapchat' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_line" type="text" maxlength="100" placeholder="Line" v-model='preview.line' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_telegram" type="text" maxlength="100" placeholder="Telegram" v-model='preview.telegram' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_googlemap" type="text" maxlength="200" placeholder="Google Map" v-model='preview.googlemaps'>
                </div>
                <template v-if="$util.isFounder()">     
                <hr  class="mt20 mb20">
                <h4 class="mb10 d-flex">{{$t('firmas.banners')}}<span class="label partner mt-auto mb-auto ml-auto mr-0">Partners</span></h4>
                <small class="info_text_sm mb10">{{$t('firmas.bannerssub')}}</small>
                <div  class="form-group">
                    <label>{{$t('partners_signup.xaisub')}}</label> <a target="_blank" href="https://calendar.x.ai/">{{$t('general.gotosite')}}</a>
                    <input class="form-control"  name="url" type="text" maxlength="200" placeholder="calendar.x.ai/mycalendar/name" v-model='preview.xai'>
                </div>
                <div  class="form-group">
                    <label>{{$t('partners_signup.calendarcom')}}</label> <a target="_blank" href="https://calendar.com/">{{$t('general.gotosite')}}</a>
                    <input class="form-control"  name="url" type="text" maxlength="200" placeholder="calendar.com/mycalendar/name" v-model='preview.calendarcom'>
                </div>
                <div  class="form-group">
                    <label>{{$t('partners_signup.wherebysub')}}</label> <a target="_blank" href="https://whereby.com">{{$t('general.gotosite')}}</a>
                    <input class="form-control"  name="url" type="text" maxlength="200" placeholder="whereby.com/mycompany" v-model='preview.whereby'>
                </div>
                </template>
            </div>
        </div> <!--
        <div v-if="$util.isFounder() && cardVal.isLanding" class="edicion_block">
            <div  @click="show.s14=!show.s14" class="navcola manito">
                <h4>Signature Banners</h4>
                <font-awesome-icon :icon="['fas','chevron-'+(show.s14?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s14" v-show-slide="show.s14">
                <div class="form-group">
                    <input class="form-control input_wicon input_snapchat" type="text" maxlength="100" placeholder="Snapchat" v-model='preview.xai' >
                </div>
                <div class="form-group">
                    <input class="form-control input_wicon input_googlemap" type="text" maxlength="200" placeholder="Google Map" v-model='preview.whereby'>
                </div>
            </div>
        </div>     -->
        <div  class="edicion_block">
            <div  @click="$util.isTrialOrPro($modal,'tracking')?show.s9=!show.s9:null" class="navcola manito">
                <h4>Tracking ID's  </h4><font-awesome-icon v-if="!$util.isTrialOrPro()" :icon="['fas','lock']" />
                <font-awesome-icon :icon="['fas','chevron-'+(show.s9?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s9" v-show-slide="show.s9">
                <div class="form-group">
                    <input class="form-control" type="text"  v-validate="{ required: true }" name="nombre" maxlength="50" :placeholder="'Google Analyitics ID'" v-model='cardVal.tracking.analytics_id'>
                    <client-only><span class="vuerror">{{ errors.first('nombre') }}</span></client-only>
                </div>
                <div class="form-group">
                    <input class="form-control" type="text"  v-validate="{ required: true }" name="nombre" maxlength="50" :placeholder="'Facebook Pixel ID'" v-model='cardVal.tracking.pixel_id'>
                    <client-only><span class="vuerror">{{ errors.first('nombre') }}</span></client-only>
                </div>
                <div class="form-group">
                    <input class="form-control" type="text"  v-validate="{ required: true }" name="nombre" maxlength="50" :placeholder="'Google Tag Manager ID'" v-model='cardVal.tracking.gtm_id'>
                    <client-only><span class="vuerror">{{ errors.first('nombre') }}</span></client-only>
                </div>
            </div>
        </div>
        <div  class="edicion_block">
            <div  @click="$util.isTrialOrPro($modal,'tracking')?show.s12=!show.s12:null" class="navcola manito">
                <h4>{{$t('general.connect')}}  </h4><font-awesome-icon v-if="!$util.isTrialOrPro()" :icon="['fas','lock']" />
                <font-awesome-icon :icon="['fas','chevron-'+(show.s12?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s12" v-show-slide="show.s12">
                <div class="form-group ">
                    <small class="mb10"><span v-if="getUser.account_details.account_custom_domain">{{$t('general.thisdomainifnot')}}({{getUser.account_details.account_custom_domain}})</span><span v-else>{{$t('general.thisdomainif')}} <a href="/account">{{$t('micuenta.micuenta')}}</a></span>. {{$t('general.thisdomain')}} </small>
                    <input class="form-control " type="text" maxlength="50" :placeholder="getUser.account_details.account_custom_domain?$util.forceHTTPS(getUser.account_details.account_custom_domain):'m.pge.link'" v-model="cardVal.custom_domain">
                    <p class="d_block mt20">{{$t('general.connectsub')}}</p>
                </div>
            </div>
        </div>
        <div  class="edicion_block">
            <div  @click="$util.isTrialOrPro($modal,'tracking')?show.s15=!show.s15:null" class="navcola manito">
                <h4>{{$t('general.lcpass')}}  </h4><font-awesome-icon v-if="!$util.isTrialOrPro()" :icon="['fas','lock']" />
                <font-awesome-icon :icon="['fas','chevron-'+(show.s15?'up':'down')]"/>
            </div>
            <div :class="'contenido '+show.s15" v-show-slide="show.s15">
                <div class="form-group ">
                    <small class="mb20">{{$t('general.lcpasssub')}}</small>
                    <input class="form-control " type="text" maxlength="50" :placeholder="$t('general.contra')" v-model="cardVal.password">
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import leftColInfoD from "./leftColInfoD.vue"
import imagePicker from '@/components/imagePicker.vue'
import switchPicker from './dinputs/switchPicker'

import {sectors} from '@/mixins/categories.js'
import {regions} from '@/mixins/regions.js'
export default {
    props:['profile','linckard'],
    data(){
        return{
            show:{
                s1:false,s2:false,s7:false,s8:false,s9:false,s10:false,s11:false,s12:false,s13:false,s14:false,s15:false,
            },
            getUser:this.profile,
            image: '',
            tags:this.$store.getters.editingLinckard.info.tags,
            mobilephone:'',
            fixedphone:'',
            sectors:sectors,
            regions:regions,
            sector:this.$store.getters.editingLinckard.sector_id,
            country:this.$store.getters.editingLinckard.info.country,
            state:this.$store.getters.editingLinckard.info.state,

            mylang:this.$store.getters.editingLinckard.locale?this.$store.getters.editingLinckard.locale:this.$store.state.userProfile.account_details.account_locale,
            langs: [
                {id:'es',name:'Español'},
                {id:'en',name:'English'},
                {id:'ar',name:'Arabic'},
                {id:'zh-CN',name:'Chinese simplified'},
                {id:'zh-TW',name:'Chinese traditional'},
                {id:'pt',name:'Portuguese'},
                {id:'ru',name:'Russian'},
                {id:'fr',name:'French'},
                {id:'ca',name:'Catalán'},
                {id:'sw',name:'Swahili'},
                {id:'el',name:'Greek'},
                {id:'tr',name:'Turkish'},
                {id:'hi',name:'Hindi'},
                {id:'ur',name:'Urdu'},
                {id:'iw',name:'Hebrew'},
                {id:'fa',name:'Persian'},
                {id:'ja',name:'Japanese'},
                {id:'ko',name:'Korean'},
                {id:'de',name:'German'},
                {id:'it',name:'Italian'},
                {id:'nl',name:'Dutch'}
            ],
            clayouts:[
                {id:1,text:'Cel/Mail/Whatsapp'},
                {id:2,text:'Cel/Mail/SMS'},
                {id:3,text:'Tel/Mail/SMS'},
                {id:4,text:'Tel/Mail/Whatsapp'},
                {id:5,text:'Cel/Mail'},
                {id:6,text:'Tel/Mail'},
                {id:7,text:'Mail/Web'},
                {id:8,text:'Web/Mail'},
                {id:9,text:'Whatsapp/Mail'},   
                {id:10,text:'Meeting/Whatsapp'},
                {id:11,text:'Meeting/Email'},   
                {id:12,text:'Meeting/Phone'},
            ],
             //Loading Signiup
            isLoading: false,
            status: '',
            sobre_active:true,
            
            
        }
    },
    beforeMount(){
        this.initSEO();

        this.mobilephone = this.getNumberOK(this.preview.mobilephone);
        this.fixedphone = this.getNumberOK(this.preview.phone);
        if (!this.preview.mobilephone_code) this.preview.mobilephone_code = this.$store.getters.countryCode;
        if (!this.preview.phone_code) this.preview.phone_code = this.$store.getters.countryCode;
        if (!this.preview.website && this.preview.url) this.preview.website = this.preview.url;

        if (!this.seo.title) {
        this.seo.title = this.preview.nombre_landing || 'My Linkcard';
        }
        if (!this.seo.desc) {
        this.seo.desc = "Linkcard is a simple yet powerful profile for a mobile world. You can edit, customize and adapt it at any time. It's ideal for professionals and companies.";
        }
        if (!this.seo.img && !this.$store.getters.editingLinckard.share_url) {
        this.seo.img = require('@/assets/images/default-avatar-linkcard.png');
        }
    },
    methods:{
        async initSEO() {
            if (!this.seo.img && !this.$store.getters.editingLinckard.share_url) {
            this.seo.img = require('@/assets/images/default-avatar-linkcard.png');
            } else if (this.seo.img.obj) {
            this.seo.img = await this.uploadAndGetUrl(this.seo.img);
            }
        },
        async uploadAndGetUrl(imgObj) {
            if (!imgObj || !imgObj.file) return null;
            
            // generar id
            const filePath = `seo/${this.$util.randomchar()}`;
            
            try {
            const url = await this.$util.upload(imgObj.file, filePath);
            console.log('url', url)
            return this.$util.removeToken(url);
            } catch (error) {
            console.error('Error uploading SEO image:', error);
            return require('@/assets/images/default-avatar-linkcard.png');
            }
        },
        getLabel(label){
            if(label=='Info')return !this.cardVal.isLanding?'Info':this.$t('bc.contact')
            else if(label=='Sobre')return this.cardVal.isLanding?'Landing':this.$t('bc.about')
            else if(label=='Compartir')return this.$t('bc.share')
            else if(label=='Extra')return 'Extra'
            else if(label=='Member')return this.$t('general.member')
        },
        onMove({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;
            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            );
        
        },
       
        onTagsUpdated(){
            this.preview.tags=this.tags
        },
        getNumberOK(myString){
            if(!myString)return '';//impotante devolver stirng
            var str = myString.substring(myString.indexOf(' ')+1)
            return str
        },
        onUpdateMobile(payload) {
            console.log('payload',payload)
            if(payload.formatInternational)this.preview.mobilephone=payload.formatInternational
            if(payload.countryCode)this.preview.mobilephone_code=payload.countryCode
        },
        onUpdateFixed(payload) {
            if(payload.formatInternational)this.preview.phone=payload.formatInternational
            if(payload.countryCode)this.preview.phone_code=payload.countryCode
        },
        regionstates(){
            if(this.country !=null && this.country.length>0){

                return this.regions.find(x => x.label == this.country).regions.map(type => type.label)
            }
            return []
        },
        addLabel (newTag) {
            if (typeof this.preview.labels !== 'object')this.preview.labels=[]
            this.preview.labels.push(newTag)
        },
        dameImg: function (id) {
            return require('@/assets/images/tipocontacto0'+id+'.svg');
        },
        
    },
    watch: {
        
        sobre_active(val){
            this.$store.getters.editingLinckard.sobre_active=val
            this.preview.sobre_active=val
        },
        mylang(locale){
            this.$store.getters.editingLinckard.locale=locale
            this.preview.locale=locale
        },
        sector(val){
            this.$store.getters.editingLinckard.sector_id=val
        },
    },
    computed: {
        cardVal () { return this.$store.getters.editingLinckard},
        preview() {return this.$store.getters.editingLinckard.info},
        seo () {return this.$store.getters.editingLinckard.seo},
        fondo () { return this.$store.getters.editingLinckard.style.sobre.fondo},  
    },
    components:{
        leftColInfoD,
        imagePicker,
        switchPicker
    }
}
</script>
<style>  
.manitomove{
    cursor: move;
}
.order_contenedor .switchet{
    margin:0px !important;
    margin-top:5px !important;
    margin-right: 5px !important;
}
.order_contenedor{
    background: white;;
    cursor: hand;
    border:0px;
    margin-bottom: 5px;;
    padding: 5px;;
    padding-left: 10px;
    padding-right: 10px;
    border-radius:10px;
}
label::before,label::after{
    display:none !important;
}  
.radiobutton_group label{
    padding-top: 10px !important;
}
.radiobutton_group label{
color:grey !important;
}
.radiobutton_group .title{
display: block !important;
font-size:10px;
}

.edicion_block .dropdown-toggle::after{
    display:none;
}

input[type="file"] {
    display: none;
}

.pretty .state label:after, .pretty .state label:before {

    display:block !important;
}
.p-default{
    margin:0 auto !important;
}



.pretty input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.pretty.p-curve .state label:after, .pretty.p-curve .state label:before{
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #d8d8d8;
    border:0px;
}

.pretty.p-default input:checked~.state label:after{

    color:#878787;
    background-color:#878787 !important;
    content: '';
    width: 10px;
    height: 10px;
    background: #878787;
    position: absolute;
    top: 0;
    margin-top: 5px;
    left: 50%;
    margin-left: -5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

}
.tags-input-badge{
font-weight:400;
margin-bottom:2px;
padding:7px !important;
}



</style>


