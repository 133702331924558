import Vue from "vue";
import { v4 as uuid } from "uuid";
import {
  auth,
  db,
  functions,
  storagesmall,
  storagelarge,
} from "@/services/firebaseConfig.js";
import helpers from "@/mixins/helpers";
import { linckard_model, profile_model, loyalty_style } from "@/mixins/data.js";
import { regions, phonecodes } from "@/mixins/regions.js";
import jwtDecode from "jwt-decode";
import { Settings } from "luxon";
import axios from "axios";
import { planes_businesscards } from "@/mixins/stripe.js";
import { isEmptyObject } from "jquery";
import mixpanel from "@/plugins/vue-mixpanel";

export default ({ app, store, route }, inject) => {
  let router = app.router;
  let i18n = app.i18n;
  let gtm = app.$gtm;
  let cookies = app.$cookies;

  inject("util", {
    isImgUploaded(evaluando) {
      return helpers.isImgUploaded(evaluando);
    },
    getCatesTemplates(type) {
      if(type === 'evnt') return [
        "Events",
      ]
      if(type === 'bc') return [
        "Business Card",
        "Resume",
      ]
      if(type === 'lib') return [
        "Social Media",
      ]
      return [
        "Automotive & Cars",
        // "Business Card", // BC
        // "Celebrations", // Vacia
        "Coming Soon",
        "Corporate",
        "COVID-19",
        "Donation",
        "eCommerce",
        // "Events", // Events
        "Food & Beverage",
        "Aesthetic & Medical",
        "Healthcare",
        "Holidays",
        "Hospitality",
        "Human Resources",
        "Insurance",
        "Landing Page",
        "Meeting",
        // "Menu",
        "Newsletter",
        "Payment",
        "Portfolio",
        "Product",
        "Promotion",
        "Real Estate",
        // "Resume", // BC
        // "Social Media", // Linkinbio
        "Spotify",
        "Startups",
      ];
    },
    gettranslabel_estoen(label) {
      if (i18n.locale == "en") return label;
      if (label == "Producto") return "Product";
      if (label == "Corporativo") return "Corporate";
      if (label == "Menu") return "Menu";
      if (label == "Donación") return "Donation";
      if (label == "Resumen") return "Resume";
      if (label == "Proximamente") return "Coming Soon";
      if (label == "Social Media") return "Social Media";
      if (label == "Business Card") return "Business Card";
      if (label == "Estetica") return "Aesthetic & Medical";
      if (label == "Bienes Raíces") return "Real Estate";
      if (label == "Portfolio") return "Portfolio";
      if (label == "Startups") return "Startups";

      if (label == "Reunion") return "Meeting";
      if (label == "Pagos") return "Payment";
      if (label == "Newsletter") return "Newsletter";

      if (label == "Promoción") return "Promotion";
      if (label == "Celebraciones") return "Celebrations";
      if (label == "Festividades") return "Holidays";
      if (label == "Insurance") return "Seguros";
      if (label == "Landing Page") return "Landing Page";
      if (label == "eCommerce") return "eCommerce";
      if (label == "Salud") return "Healthcare";
      if (label == "Spotify") return "Spotify";
      if (label == "Automotriz") return "Automotive & Cars";
      if (label == "Hospitalidad") return "Hospitality";
      if (label == "Comidas & Bebidas") return "Food & Beverage";
      if (label == "Recursos Humanos") return "Human Resources";
      if (label == "Eventos") return "Events";
    },
    gettranslabel_entoes(label) {
      if (i18n.locale == "en") return label;
      if (label == "Product") return "Producto";
      if (label == "eCommerce") return "eCommerce";
      if (label == "Healthcare") return "Salud";
      if (label == "Insurance") return "Seguros";
      if (label == "Corporate") return "Corporativo";
      if (label == "Menu") return "Menu";
      if (label == "Spotify") return "Spotify";
      if (label == "Aesthetic & Medical") return "Estetica";
      if (label == "Meeting") return "Reunion";
      if (label == "Startups") return "Startups";
      if (label == "Payment") return "Pagos";
      if (label == "Newsletter") return "Newsletter";
      if (label == "Coming Soon") return "Proximamente";
      if (label == "Resume") return "Resumen";
      if (label == "Celebrations") return "Celebraciones";
      if (label == "Holidays") return "Festividades";
      if (label == "Landing Page") return "Landing Page";
      if (label == "Social Media") return "Social Media";
      if (label == "Business Card") return "Business Card";
      if (label == "Real Estate") return "Bienes Raíces";
      if (label == "Portfolio") return "Portfolio";
      if (label == "Promotion") return "Promoción";
      if (label == "Automotive & Cars") return "Automotriz";
      if (label == "Hospitality") return "Hospitalidad";
      if (label == "Food & Beverage") return "Comidas & Bebidas";
      if (label == "Human Resources") return "Recursos Humanos";
      if (label == "Events") return "Eventos";
    },
    shuffle(array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      array.sort((a, b) => (a.premium ? 1 : b.premium ? -1 : 0));
      return array;
    },
    getImage(user = store.state.userProfile) {
      return helpers.getImage(user);
    },
    getFirstLincForImage: function(user) {
      if (
        user &&
        user.account_details &&
        user.account_details.account_picture &&
        user.account_details.account_picture.length > 10
      )
        return user.account_details.account_picture;
      else if (
        user &&
        user.linckards &&
        user.linckards.length > 0 &&
        user.linckards[0].info.img !== null &&
        (this.isImgUploaded(user.linckards[0].info.img) ||
          (user.linckards[0].info.img && user.linckards[0].info.img.length > 0))
      ) {
        return user.linckards[0];
      } else {
        return null;
      }
    },
    memberObj(getUser, linkcard) {
      return helpers.memberObj(getUser, linkcard);
    },
    getKeywords(nombre, username, number, email, dni) {
      return helpers.getKeywords(nombre, username, number, email, dni);
    },
    guestObj(getUser, linkcard) {
      return helpers.guestObj(getUser, linkcard);
    },
    getDate() {
      return helpers.getDate();
    },
    getCountryCode(account_country) {
      return helpers.getCountryCode(account_country, regions);
    },
    getCountryPhoneCode(country_code) {
      return helpers.getCountryPhoneCode(country_code, phonecodes);
    },
    getDateAS(profile, date) {
      return helpers.getDateAS(profile, date);
    },
    isValidDate(d) {
      return helpers.isValidDate(d);
    },
    parseDate(profile, datestring) {
      return helpers.parseDate(profile, datestring);
    },
    linckardObjEvent(linc, newUser) {
      let eveobj = {
        event_id: linc.event_id,
        id: linc.id,
        status: linc.status,
        info: {
          nombre: linc.info.nombre,
          trabajo: linc.info.trabajo,
          img: linc.info.img,
        },
      };

      eveobj.uid = newUser.uid;
      eveobj.username = newUser.username;
      return eveobj;
    },
    linckardObj(linc) {
      return helpers.linckardObj(linc);
    },
    getActiveLandings(user = store.state.userProfile) {
      return helpers.getActiveLandings(user);
    },
    getActiveLinckards(user = store.state.userProfile) {
      return helpers.getActiveLinckards(user);
    },
    getLandingPages(user = store.state.userProfile) {
      if (!user || !user.linckards) return [];
      var lincs = user.linckards;
      lincs = lincs.filter((u) => {
        let lincOK = u.isLanding;
        return lincOK;
      });
      return lincs;
    },
    getBusinessCards(user = store.state.userProfile) {
      if (!user || !user.linckards) return [];
      var lincs = user.linckards;
      lincs = lincs.filter((u) => {
        let lincOK = !u.isLanding && !(u.event_id >= 0 || u.loyalty_id >= 0);
        return lincOK;
      });
      return lincs;
    },

    loadBenefitDrags(linckard, benefits) {
      if (!benefits) return linckard.sobredrags;
      benefits = benefits.filter((u) => {
        return !u.deleted;
      });
      if (linckard.sobredrags == undefined) linckard.sobredrags = [];
      if (linckard.sobredrags.length > 0)
        linckard.sobredrags = linckard.sobredrags.filter((u) => {
          return !u.auto;
        });
      if (linckard.sobredrags_auto == undefined) linckard.sobredrags_auto = [];

      for (let i in benefits) {
        ///siempre lo genero
        let benef = benefits[i];
        let findAuto = linckard.sobredrags_auto.find((e) => {
          return e.benefit_id == benef.id;
        });
        let autoObject = {
          auto: true,
          index_pos: findAuto ? findAuto.index_pos : linckard.sobredrags.length,
          benefit_id: benef.id,
          value: benef.name,
          desc: benef.desc,
          foto: findAuto ? findAuto.foto : "21",
          //nombre:'',
          compo: findAuto ? findAuto.compo : "editPremio",
          icon_size: findAuto ? findAuto.icon_size : "45px",
          icon_color: findAuto ? findAuto.icon_color : "#535353",
          size: findAuto ? findAuto.size : "24px",
          weight: findAuto ? findAuto.weight : "500",
          lineh: findAuto ? findAuto.lineh : "40px",
          color: findAuto ? findAuto.color : "#494949",
          family: findAuto ? findAuto.family : "Oswald",
          desc_weight: findAuto ? findAuto.desc_weight : "400",
          desc_size: findAuto ? findAuto.desc_size : "13px",
          desc_lineh: findAuto ? findAuto.desc_lineh : "28px",
          desc_color: findAuto ? findAuto.desc_color : "#494949",
          desc_family: findAuto ? findAuto.desc_family : "Open Sans",
          img: findAuto ? findAuto.img : "",
          tipo: findAuto ? findAuto.tipo : "freemium",
          design: true,
        };
        linckard.sobredrags.splice(autoObject.index_pos, 0, autoObject);
      }
      return linckard.sobredrags;
    },
    contactObj(contact, linc_id = -1) {
      return helpers.contactObj(contact, linc_id);
    },

    updateAlgolia(alg_index, getUser) {
      return helpers.updateAlgolia(alg_index, getUser);
    },
    buildUserObject(authData) {
      let { email, displayName, uid, photoURL } = authData.user;
      let user = {};
      user["email"] = email;
      user["name"] = displayName;
      user["uid"] = uid;
      user["picture"] = photoURL;
      return user;
    },
    isFreemiumPlan(plan) {
      return plan.plans[0].price == 0;
    },
    hasOnePremiumPlan(profile = store.state.userProfile, type = "bc") {
      return helpers.hasOnePremiumPlan(profile, type);
    },
    atleastOnePlan(profile, plans) {
      for (let i in plans) {
        let product = plans[i];
        if (this.hasCurrentProduct(profile, product)) return true;
      }
      return false;
    },
    diffDays(date) {
      return helpers.diffDays(date);
    },
    getAgentPassword() {
      let code = this.randomchar();
      if (code.length == 5) pass += "0";
      if (code.length == 4) pass += "00";
      return code;
    },
    randomchar() {
      return helpers.randomchar();
    },

    editBenefit(elid, objMain, modal) {
      modal.show("modal-beneficio", { objMain: objMain, id: elid });
    },
    async deleteBenefit(elid, objMain, modal) {
      let dis = this;
      let index = objMain.benefits.findIndex((e) => {
        return e.id == elid;
      });
      modal.show("dialog", {
        title: i18n.t("notis.atencion"),
        text: i18n.t("general.estaseguro"),
        buttons: [
          {
            title: i18n.t("notis.si"),
            handler: async () => {
              objMain.benefits[index].status = "deleted";
              objMain.benefits[index].deleted = true;
              dis.saveBenefits(objMain.id, objMain.benefits);
              modal.hide("dialog");
            },
          },
          { title: i18n.t("general.cerrar") },
        ],
      });
    },
    async duplicateBenefit(elid, objMain) {
      let index = objMain.benefits.findIndex((e) => {
        return e.id == elid;
      });
      let newOb = JSON.parse(JSON.stringify(objMain.benefits[index]));
      newOb.id = objMain.benefits.length + 1;
      objMain.benefits.splice(index, 0, newOb);
      this.saveBenefits(objMain.id, objMain.benefits);
    },
    addBenefit(objMain, modal) {
      modal.show("modal-beneficio", { objMain: objMain, id: null });
    },
    async saveBenefits(loyalty_id, benefits) {
      let getUser = store.state.userProfile;
      let elindex = getUser.loyaltys.findIndex((e) => {
        return e.id == loyalty_id;
      });
      getUser.loyaltys[elindex].benefits = benefits;
      await db
        .collection("users")
        .doc(getUser.uid)
        .update({ loyaltys: getUser.loyaltys });
      this.notif_success();
    },
    openLinkURL(id, username = false) {
      if (window) {
        if (!username && store) username = store.state.userProfile.username;
        window.open(this.cardURL() + username + "/" + id);
      }
    },
    hasCurrentProduct(profile, product, annual) {
      if (!profile) return false;

      if (
        product.prod_id == "prod_HYa7rY0AqBdROa" &&
        profile.stripe_details &&
        profile.stripe_details.stripe_subscriptions &&
        profile.stripe_details.stripe_subscriptions[0].prod_id ==
          "prod_LvLdrTlc0D3cOi"
      )
        return true;
      let ja = profile.stripe_details.stripe_subscriptions.find((e) => {
        return (
          e.prod_id == product.prod_id &&
          (e.plan_id == (annual ? "pro_2023_y" : "pro_2023_m") ||
            e.plan_id == "lifetime_partners_mp")
        );
      });
      return ja;
    },
    showCameraIcon() {
      if (this.isStandAlone() && this.isIOSSafari()) return false;
      return true;
    },
    dismissBarra() {
      cookies.set("__sacarbarra", true);
    },
    async setCookie() {
      cookies.removeAll();
      route.path = null;

      let token = await auth.currentUser.getIdToken();
      let decoded = jwtDecode(token);
      if (decoded) {
        let email = decoded.email;
        console.log("setea cookie", email);
        cookies.set("__session", token);
      } else {
        cookies.remove("__session");
        cookies.removeAll();
        store.commit("setCurrentUser", null);
        store.commit("setUserProfile", null);
      }
    },
    logout() {
      store.dispatch("signOut").then(() => {
        //if(this.isTesting()){
        cookies.remove("__session");
        cookies.removeAll();
        //}

        store.commit("setCurrentUser", null);
        store.commit("setUserProfile", null);
        router.push("/login");
      });
    },
    clearUserData(eluser) {
      return helpers.clearUserData(eluser);
    },
    getAverage(partnerUser) {
      let cant = 0;
      if (!partnerUser.reviews || !partnerUser.reviews.data) return 0;
      let reviews = partnerUser.reviews.data;
      reviews.forEach((review) => {
        cant += parseInt(review.stars);
      });
      if (!cant) return 0;
      return Math.round(cant / reviews.length);
    },
    compPrefix() {
      return router.history.current.name + "_";
    },
    leaveReview(partner, from, review) {
      //review es un user para el catalogo
      var dis = this;
      review.uid_from = from.uid;
      if (!partner.denom_reviews) partner.denom_reviews = [];
      if (!partner.reviews || Array.isArray(partner.reviews))
        partner.reviews = { data: [], users: [] };
      if (!partner.reviews.data) partner.reviews.data = [];
      if (!partner.reviews.users) partner.reviews.users = [];
      partner.reviews.data.push(review);
      partner.reviews.users.push(this.contactObj(from));
      if (!partner.denom_reviews.includes(from.uid))
        partner.denom_reviews.push(from.uid);

      partner.rating = this.getAverage(partner);

      let toUpdate = JSON.parse(JSON.stringify(partner));
      db.collection("users")
        .doc(toUpdate.uid)
        .update({
          rating: toUpdate.rating,
          "reviews.users": toUpdate.reviews.users,
          "reviews.data": toUpdate.reviews.data,
          denom_reviews: toUpdate.denom_reviews,
        })
        .then(function() {
          //bus.$emit(dis.compPrefix()+'switchMainPartnerUser',partner)
          dis.notif_success(i18n.t("notis.review"));
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    },
    styleLoyaltyLinkcard(def, loyalty_id) {
      if (loyalty_id >= 0) def.loyalty_id = loyalty_id;
      def.style.membre = loyalty_style;

      def.style.general.menu_sup.background = "#d8983d";

      //Paso al info

      def.style.info.fondo.background = def.style.membre.fondo.background;
      def.style.info.cargo.color = def.style.membre.cargo.color;
      def.style.info.caja_logo.background =
        def.style.membre.caja_logo.background;

      def.style.info.botones_c1.color = def.style.membre.creditos.color;
      def.style.info.botones_c1.background =
        def.style.membre.creditos.background;
      def.style.info.botones_c2.color = def.style.membre.creditos.color;
      def.style.info.botones_c2.background =
        def.style.membre.creditos.background;

      return def;
    },
    styleEventLinkcard(def, event_id) {
      if (event_id >= 0) def.event_id = event_id;
      def.style.general.menu_sup.background = "#3abcfc";
      def.style.info.fondo.background = "#d8983d";
      def.style.info.caja_logo.background = "#3abcfc";
      def.style.info.botones_c1.color = "#CD9A4E";
      def.style.info.botones_c1.background = "#FFFFFF";
      def.style.info.botones_c2.color = "#CD9A4E";
      def.style.info.botones_c2.background = "#FFFFFF";
      return def;
    },
    fakeLinkcard(linc) {
      let def = JSON.parse(JSON.stringify(linckard_model));

      def.id = linc.id;
      if (linc.event_id >= 0) def = this.styleEventLinkcard(def, linc.event_id);
      else if (linc.loyalty_id >= 0)
        def = this.styleLoyaltyLinkcard(def, linc.loyalty_id);

      def.info = {
        ...linckard_model.info,
        ...linc.info,
      };
      return def;
    },
    async addOrUpdateSector(modal, sectorObj) {
      var dis = this;
      let profile = store.state.userProfile;
      if (!profile.sectors) profile.sectors = [];

      if (sectorObj.id != null) {
        //update
        let index = profile.sectors.findIndex(function(i) {
          return i.id == sectorObj.id;
        });
        profile.sectors.splice(index, 1, sectorObj);
        //Aca entra si ya existia
        db.collection("users")
          .doc(profile.uid)
          .update({ sectors: profile.sectors })
          .then(function() {
            dis.notif_success();
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });
      } else {
        let index = profile.sectors.findIndex(function(i) {
          return i.name == sectorObj.name;
        });
        if (index >= 0) return profile.sectors[index];

        profile.stats.c_sectors = Math.max(...profile.sectors.map(o => o.id)) + 1
        var newSector = {
          id: profile.stats.c_sectors,
          name: sectorObj.name,
          type: sectorObj.type,
          status: "active",
        };
        if (router.history.current.name.includes("landing"))
          newSector.isLanding = true;
        profile.sectors.push(newSector);
        return db
          .collection("users")
          .doc(profile.uid)
          .update({ sectors: profile.sectors, stats: profile.stats })
          .then(function() {
            dis.notif_success();
            return newSector;
          })
          .catch(function(error) {
            console.error("Error sec adding document: ", error);
          });
      }
    },
    getfromBus(uid) {
      let index = store.state.uploadBus.findIndex(function(i) {
        return i.id == uid;
      });
      let obj = store.state.uploadBus[index];
      return obj;
    },
    pushToBus(obj) {
      obj.id = this.randomchar();
      store.state.uploadBus.push(obj);
    },
    hasSignaPartner(signa) {
      return signa && (signa.xai || signa.whereby || signa.calendarcom);
    },
    hasSignaBanner(signa) {
      let img = signa && signa.banner ? signa.banner.img : null;
      return img != null;
    },
    getSignaBanner(signa) {
      let img = signa && signa.banner ? signa.banner.img : null;
      if (!img) return require("@/assets/images/firmas_banner.svg");
      else if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img);
    },
    async uploadImages(nueva) {
      let evaluando = "";
    
      if (nueva.seo && nueva.seo.img && typeof nueva.seo.img === 'object') {
        try {
          if (this.isImgUploaded(nueva.seo.img)) {
            let bus = this.getfromBus(nueva.seo.img.id);
            let url = await this.getImageUrl(bus);
            nueva.seo.img = url;
    
            if (bus && bus.file_round) {
              let url_round = await this.getImageUrl(bus, true);
              nueva.seo.img_round = url_round;
            }
          }
        } catch (error) {
          console.error('Error processing SEO image:', error);
        }
      }
    
      for (var key1 in nueva) {
        evaluando = nueva[key1];
        if (evaluando == null || evaluando == undefined) continue;
    
        if (key1 == "img" && this.isImgUploaded(evaluando)) {
          let bus = this.getfromBus(evaluando.id);
          let url = await this.getImageUrl(bus);
          nueva[key1] = url;
        }
    
        if (evaluando.constructor !== {}.constructor || key1 == "img") continue;
        for (var key2 in nueva[key1]) {
          evaluando = nueva[key1][key2];
          if (evaluando == null || evaluando == undefined) continue;
    
          if (key2 == "img" && this.isImgUploaded(evaluando)) {
            let bus = this.getfromBus(evaluando.id);
            let url = await this.getImageUrl(bus);
            nueva[key1][key2] = url;
            
            if (bus && bus.file_round) {
              try {
                let url_round = await this.getImageUrl(bus, true);
                nueva[key1][key2 + "_round"] = url_round;
              } catch (error) {
                console.error('Error processing round image:', error);
              }
            }
          }
    
          if (evaluando.constructor !== {}.constructor || key2 == "img") continue;
          for (var key3 in nueva[key1][key2]) {
            evaluando = nueva[key1][key2][key3];
            if (evaluando == null || evaluando == undefined) continue;
    
            if (key3 == "img" && this.isImgUploaded(evaluando)) {
              let url = await this.getImageUrl(this.getfromBus(evaluando.id));
              nueva[key1][key2][key3] = url;
            }
    
            if (evaluando.constructor !== {}.constructor || key3 == "img") continue;
            for (var key4 in nueva[key1][key2][key3]) {
              evaluando = nueva[key1][key2][key3][key4];
              if (evaluando == null || evaluando == undefined) continue;
    
              if (key4 == "img" && this.isImgUploaded(evaluando)) {
                let url = await this.getImageUrl(this.getfromBus(evaluando.id));
                nueva[key1][key2][key3][key4] = url;
              }
            }
          }
        }
      }
    
      if (!nueva["sobredrags"]) nueva["sobredrags"] = [];
      for (let index in nueva["sobredrags"]) {
        let img = nueva["sobredrags"][index].img;
        if (this.isImgUploaded(img)) {
          let url = await this.getImageUrl(this.getfromBus(img.id));
          nueva["sobredrags"][index].img = url;
        }
        let place = nueva["sobredrags"][index].place;
        if (place) {
          let newplace = {
            formatted_address: place.formatted_address,
            place_id: place.place_id,
          };
          nueva["sobredrags"][index].place = newplace;
        }
      }
    
      return JSON.parse(JSON.stringify(nueva));
    },
    async createProfile(uid, email, name, img = "", quien = {}) {
      let profileInfo = JSON.parse(JSON.stringify(profile_model));
      profileInfo.uid = uid;

      if (quien.referral) profileInfo.referral = quien.referral;
      if (quien.partner) profileInfo.partner = quien.partner;
      if (quien.friend && quien.friend.email && quien.friend.code)
        profileInfo.friend = quien.friend;

      profileInfo.email = email;
      profileInfo.account_details.account_date = helpers.getDate();
      profileInfo.account_details.account_datetime = new Date().getTime();
      profileInfo.account_details.account_origin = "mp";

      profileInfo.account_details.account_source = quien.referral
        ? "referral"
        : quien.partner
        ? "partner"
        : quien.friend
        ? "friend"
        : "";

      if (quien.template_id)
        profileInfo.account_details.account_template = quien.template_id;
      profileInfo.account_details.signup_provider = quien.providerId
        ? quien.providerId
        : "mail";

      if (name) profileInfo.account_details.account_name = name;
      if (img) profileInfo.account_details.account_picture = img;
      profileInfo.account_details.account_date_format = "le";
      profileInfo.account_details.account_locale = this.getLocale();
      profileInfo.account_details.account_domain = email.split("@")[1];

      profileInfo.stripe_details.stripe_subscriptions = [];
      for (let i in this.getFreemiumPlans()) {
        let prod = this.getFreemiumPlans()[i];
        let plan = prod.plans[0];
        let obj = {
          plan_id: plan.plan_id,
          prod_id: prod.prod_id,
          type: prod.type,
        };
        if (
          quien.partner == "stackcommerce" &&
          quien.code == "pro4life_stack"
        ) {
          //pro for life (3bc)
          obj.plan_id = "lifetime_partners_mp";
          obj.prod_id = "prod_LvLdrTlc0D3cOi";
          obj.type = "bc";
        }
        if (quien.partner == "stackcommerce" && quien.code == "pro40_stack") {
          //40bc
          obj.plan_id = "pro40_stack";
          obj.prod_id = "prod_MZBA9vBN2Zi6NX";
          obj.type = "bc";
        }
        profileInfo.stripe_details.stripe_subscriptions.push(obj);
      }

      //Username
      profileInfo.username = this.parseUsername(
        profileInfo.account_details.account_name
      );
      let querySnapshot = await db
        .collection("users")
        .where("username", "==", profileInfo.username)
        .get();
      if (querySnapshot.size > 0)
        profileInfo.username =
          profileInfo.username + Math.floor(Math.random() * 1000 + 1);

          profileInfo.stripe_details.stripe_plan = {
            "max_linkcards": "1",
            "uid": "Y7Z0zIWxG8QmnKoNhgbR339AbW22",
            "plan_id": "freemium_bc",
            "prod_id": "prod_F46r21GmoYrvdV",
            "status": "active",
            "subscription_id": "sub_1QDww2ES9JaRReUPQ3piI6dU",
            "name": "BC Freemium",
            "price": 0,
            "period": "2024-11-25T23:28:50.000Z",
            "interval": "month",
            "type": "bc"
        }
        
      console.log("profileInfo", profileInfo);
      return db
        .collection("users")
        .doc(profileInfo.uid)
        .set(profileInfo)
        .then(() => {
          return profileInfo;
        })
        .catch((error) => {
          throw new functions.https.HttpsError(
            "invalid-argument",
            error.message
          );
        });
    },
    parseUsername(uname) {
      return uname
        .toLowerCase()
        .replace(/[àáâãäå]/g, "a")
        .replace(/[ñ]/g, "n")
        .replace(/[éè]/g, "e")
        .replace(/[íì]/g, "i")
        .replace(/[üú]/g, "u")
        .replace(/[óò]/g, "o")
        .replace(/[^A-Z0-9]/gi, "");
    },
    parseLocale(locale) {
      if (locale.includes("-")) locale = locale.split("-")[0];
      if (locale != "en" && locale != "es") locale = "en";
      return locale;
    },
    selectText(node) {
      node = document.getElementById(node);

      if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
      } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        console.warn("Could not select text in node: Unsupported browser.");
      }
    },
    getTemplateMerged(abuscar) {
      for (let i = 0; i < abuscar.length; i++) {
        let linc = abuscar[i];
        let idtemp = linc.template_load;
        if (idtemp != undefined) {
          let eliddd = abuscar.findIndex((e) => {
            return e.id == idtemp;
          });
          delete abuscar[eliddd].seo;
          if (eliddd != undefined) {
            let temp1 = abuscar[eliddd];
            let temp2 = temp1;
            if (linc.template_id)
              temp2 = store.state.templates.find((e) => {
                return e.id == linc.template_id;
              });
            abuscar[i] = {
              ...temp1,
              ...temp2,
              ...linc,
            };
          }
        }
      }
      return abuscar;
    },
    async getCleanedLinkcards(profile) {
      for (let i in profile.linckards) {
        if (!profile.linckards[i]) continue;
        if (profile.linckards[i].template_load != undefined) {
          delete profile.linckards[i].sobredrags_auto;
          delete profile.linckards[i].sobredrags;
          delete profile.linckards[i].style;
          delete profile.linckards[i].tracking;
          delete profile.linckards[i].tabs;
          delete profile.linckards[i].tags;
        } else {
          console.log("nordec cleaned");
        }
      }
      return JSON.parse(JSON.stringify(profile.linckards));
    },
    async guardarLincsGeneralEnterprise(
      lincs,
      profile = store.state.userProfile
    ) {
          let nueva;
          nueva.profile_id = profile.uid;
          nueva.profile_username = profile.username;
          nueva.uid = uuid();
          nueva.status = "active";
          profile.stats.c_linckards++;
          nueva.id = profile.stats.c_linckards;
          if (store.state.editor.active) store.state.editor.active.id = nueva.id;
  
        nueva.share_url = await this.saveDynamicLink(profile, nueva);
        if (
          nueva.share_url &&
          store.state.editor &&
          store.state.editor.active /*por si esta duplicando*/
        )
          store.state.editor.active.share_url = nueva.share_url;

          if (!nueva.fecha) nueva.fecha = this.getDate();
          if (!profile.linckards) profile.linckards = [];
    
          //Sancor
          await db
            .collection("linkcards")
            .doc(nueva.uid)
            .set(nueva);

      try {
        if (this.isEnterprise()) {
          for (let i in lincs) {
            let linc = lincs[i];
            console.log(linc.uid);
            if (linc.uid)
              await db
                .collection("linkcards")
                .doc(linc.uid)
                .update(linc);
          }
        } else {
          await db
            .collection("users")
            .doc(profile.uid)
            .update({ linckards: lincs });
        }
      } catch (e) {
        console.log(e);
        return false;
      }
      return true;
    },
    async guardarLinckardGeneral(nueva, duplicated = false, profile = null) {
      var dis = this;
      if (!profile) profile = store.state.userProfile;
      store.state.editor.isSaving = true;
      nueva = await this.uploadImages(nueva);
      if (!nueva) {
        dis.notif_error("Linkcard error");
        return;
      }

      //Se id si es nueva
      if (nueva.id == undefined) {
        nueva.profile_id = profile.uid;
        nueva.profile_username = profile.username;
        nueva.uid = uuid();
        nueva.status = "active";
        profile.stats.c_linckards++;
        nueva.id = profile.stats.c_linckards;
        if (store.state.editor.active) store.state.editor.active.id = nueva.id;
        console.log(
          "Nueva link",
          nueva.id,
          nueva.uid,
          profile.stats.c_linckards
        );
      } else {
        console.log("Existente link", nueva.id, profile.stats.c_linckards);
      }
      console.log('guardarLinckardGeneral nueva', nueva)

      nueva.share_url = await this.saveDynamicLink(profile, nueva);
      if (
        nueva.share_url &&
        store.state.editor &&
        store.state.editor.active /*por si esta duplicando*/
      )
        store.state.editor.active.share_url = nueva.share_url;

      if (!nueva.fecha) nueva.fecha = this.getDate();
      if (!profile.linckards) profile.linckards = [];
      let index = profile.linckards.findIndex(function(i) {
        return i.id == nueva.id;
      });

      //Sancor
      await db
        .collection("linkcards")
        .doc(nueva.uid)
        .set(nueva);
      console.log("nueva.template_id", nueva.template_id);
      //Nuevos templates a aplicar 261
      if (nueva.template_load != undefined) {
        //elimino nuevamanete //NORDEC
        console.log("norde, elimina");
        delete nueva.sobredrags_auto;
        delete nueva.sobredrags;
        delete nueva.style;
        delete nueva.tracking;
        delete nueva.tabs;
        delete nueva.tags;
      } else {
        console.log(nueva);
      }

      //SANCOR

      if (index >= 0) profile.linckards[index] = nueva;
      else profile.linckards.push(nueva);

      //console.log(JSON.stringify(profile.linckards))
      let lincstoUpdate = await this.getCleanedLinkcards(profile);

      //Actualizo las active
      profile.stats.cant_linkcards_active = dis.getActiveLinckards(
        profile
      ).length;
      profile.stats.cant_linkcards = dis.getBusinessCards().length;

      profile.stats.cant_mp_active = dis.getActiveLandings(profile).length;
      profile.stats.cant_mp = dis.getLandingPages().length;

      if (!profile.account_details.account_name)
        profile.account_details.account_name = nueva.info.nombre;
      if (!profile.account_details.account_subtitle)
        profile.account_details.account_subtitle = nueva.info.trabajo;
      if (!profile.account_details.account_state)
        profile.account_details.account_state = nueva.info.state;
      if (!profile.account_details.account_category)
        profile.account_details.account_category = nueva.info.category;
      if (!profile.account_details.account_picture)
        profile.account_details.account_picture = nueva.info.img;

        if (!profile.account_details.account_state)
        profile.account_details.account_state = '';
        if (!profile.account_details.account_category)
        profile.account_details.account_category = '';

      //console.log('pasa2')
      if (!lincstoUpdate) return;
      if (this.isEnterprise()) lincstoUpdate = [];

      return db
        .collection("users")
        .doc(profile.uid)
        .update({
          linckards: lincstoUpdate,
          stats: profile.stats,
          account_details: profile.account_details,
        })
        .then((result) => {
          store.state.editor.isSaving = false;
          dis.initTrial(profile, "bc");
          let template_id = nueva.template_id ? nueva.template_id : "";
          let template_cate = nueva.template_category
            ? nueva.template_category
            : "";
          dis.registerStat(index >= 0 ? "save" : "create", {
            template_id: template_id,
            template_category: template_cate,
          });
          console.log("template_cate:", template_cate);
          store.commit("resetSaveLinckardVersioning");
          return nueva;
        })
        .catch((error) => {
          store.state.editor.isSaving = false;
          dis.notif_error(error.message);
          return nueva;
        });
    },
    sendWhatsapp() {
      const twilio_whatsapp = functions.httpsCallable("twilio_whatsapp");
      twilio_whatsapp({ to: "+5491154999795" })
        .then(async (msg) => {
          console.log(msg);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openHelp() {
      window.open("https://m.pge.link/linkcard/552", "_blank")

    },
    getFirstSobreImg(link) {
      if (!link.sobredrags) return "";
      for (let i in link.sobredrags) {
        let drag = link.sobredrags[i];
        if (drag.compo != "editImagen" && drag.compo != "editAvatar") continue;
        console.log(drag);
        if (drag.img) return drag.img;
      }
      return "";
    },
    async saveDynamicLink(profile, nueva) {
      console.log('saveDynamicLink')
      let st = nueva.seo && nueva.seo.title ? nueva.seo.title : (nueva.isLanding ? nueva.info.nombre_landing : nueva.info.nombre);

      //let sd= nueva.seo && nueva.seo.desc?nueva.seo.desc:nueva.isLanding?nueva.info.nombre_landing:nueva.info.trabajo
      let sd =
        nueva.seo && nueva.seo.desc ? nueva.seo.desc : nueva.info.trabajo;
      //let si= nueva.seo && nueva.seo.img?nueva.seo.img:nueva.isLanding?this.getFirstSobreImg(nueva):nueva.info.img
      let si = nueva.seo && nueva.seo.img 
        ? (typeof nueva.seo.img === 'object' 
          ? await this.uploadAndGetUrl(nueva.seo.img) 
          : nueva.seo.img)
        : (nueva.isLanding 
          ? nueva.info.img 
          : this.getFirstSobreImg(nueva));
    
      if (si && typeof si === 'object') {
        si = await this.uploadAndGetUrl(si);
      }
    
      if (!si || !si.startsWith('http')) {
        si = 'https://firebasestorage.googleapis.com/v0/b/linkcard-small-production/o/profile%2Fw2op2hio?alt=media';
      }
      //if(profile.email=='daiana.bossana@sancorsalud.com.ar')si='https://firebasestorage.googleapis.com/v0/b/linkcard-small-production/o/profile%2Fkb74rsae?alt=media'
      console.log('si', si, "st", st, "sd", sd)

      if (!si || !st || !sd) return null;

      const urlBuild = require("build-url");
      let url = urlBuild("https://pge.link/m/", {
        queryParams: {
          link:
            this.cardURL(profile, nueva) + profile.username + "/" + nueva.id,
          st: st,
          sd: sd,
          si: si,
        },
      });
      //console.log(this.cardURL(profile,nueva)+profile.username+'/'+nueva.id)
      const body = {
        longDynamicLink: url,
        suffix: {
          option: "short",
        },
      };
      return axios({
        method: "post",
        url:
          "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBVuo_tYafMa98SWuttEacSmbO17HI4edE",
        headers: { "Content-Type": "application/json" },
        data: body,
      })
        .then((response) => {
          console.log("shortLink", response.data.shortLink);
          return response.data.shortLink;
        })
        .catch((e) => {
          console.log(e);
          return null;
        });
    },
    getLincImageLoyalty(element, size = "111x111") {
      let img = element && element.membre ? element.membre.img : null;
      if (!img)
        return "https://firebasestorage.googleapis.com/v0/b/linckard-1545486188489.appspot.com/o/profile_nophoto_gold.png?alt=media";
      else if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img, size);
    },
    isBCDash() {
      let isdash =
        router.history.current.name === "sector-sector-linkcards" ||
        router.history.current.name === "sector-sectors-linkcards";
      isdash = isdash || router.history.current.name.includes("signature");
      isdash = isdash || router.history.current.name == "stats-linkcards";
      isdash = isdash || router.history.current.name == "leads-linkcards";
      return isdash;
    },

    isLDDash() {
      let isdash =
        router.history.current.name === "sector-sector-landings" ||
        router.history.current.name === "sector-sectors-landings";
      isdash = isdash || router.history.current.name == "stats-landings";
      isdash = isdash || router.history.current.name == "leads-landings";
      return isdash;
    },
    getLincImage(element, size = "111x111") {
      let img = element && element.info ? element.info.img : null;
      if (img && !img.obj && img.includes("?usp=share_link"))
        img = img.replace("?usp=share_link", "");
      if (!img) return require("@/assets/images/profile_nophoto.png");
      else if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img, size);
    },

    simpleMetaHead() {
      return {
        title: router.history.current.name,
        /*link: [
                  {rel: "shortcut icon",type: "image/x-icon",href: '/'+"favi.png"},
                  {rel: "mask-icon",sizes:'any',href: '/'+"appicon.png"},
                  {rel: 'apple-touch-icon',sizes:'192x192', href:'/'+'appicon.png'},
                  {rel: 'apple-touch-icon-precomposed',sizes:'114x114', href: '/'+'appicon114.png' },
              ],/*
              script:[
                  {src: 'https://js.stripe.com/v3/' },
                  {src: 'https://wchat.freshchat.com/js/widget.js' },
              ]*/
      };
    },
    switchCompInfo(comp, bus) {
      if (comp == "design") store.state.sobreComp = "leftColSobreDD";
      else if (comp == "settings") store.state.sobreComp = "leftColSettings";
      else store.state.sobreComp = "leftColSobreDA";
      if (document.getElementById("edicionLeft"))
        document.getElementById("edicionLeft").scrollTo(0, 0);
      //bus.$emit('switchInfoMode',comp)
      store.state.infoMode = comp;
    },
    modalShare(linkcard, modal) {
      modal.show("modal-share", { linkcard: linkcard });
    },
    modalShareAndSend(linkcard, modal) {
      modal.show("modal-share", { linkcard: linkcard, send: true });
    },
    isStatBCOK(elid) {
      if (!elid) return false;
      let getUser = store.state.userProfile;
      for (let i in getUser.linckards) {
        let linc = getUser.linckards[i];
        if (linc.id == elid && !linc.isLanding) {
          return true;
        }
      }
      return false;
    },
    isStatLDOK(elid) {
      let getUser = store.state.userProfile;
      for (let i in getUser.linckards) {
        let linc = getUser.linckards[i];
        if (linc.id == elid && linc.isLanding) {
          return true;
        }
      }
      return false;
    },
    insideSector() {
      return (
        router.history.current.name.includes("sector") &&
        !router.history.current.name.includes("sectors")
      );
    },
    showSectors(landing = false) {
      let getUser = store.state.userProfile;
      let lincs =
        getUser.linckards; /*.filter(u=>{if(landing)return u.isLanding;else return !u.isLanding })*/
      let sectors =
        getUser.sectors; /*.filter(u=>{if(landing)return u.isLanding;else return !u.isLanding })*/
      return lincs.length > 10 || sectors.length > 1;
    },
    isUpdatePro(profile) {
      //esto se corre cuando se guarda una limckard, es para mantener el is pro actualizado
      //puede ser pro manual, o
      //if(profile.isPro)return true
      if (this.hasOnePremiumPlan(profile, "bc")) return true;
      return false;
    },
    hasLoyaltys(profile = store.state.userProfile) {
      return (
        (profile && profile.loyaltys && profile.loyaltys.length > 0) ||
        this.isFounder() ||
        this.isTesting() ||
        profile.hasLoyalty ||
        profile.stripe_details?.stripe_plan?.hasLoyalty
      );
    },
    hasLoyalty(profile) {
      let event = profile.linckards.filter((u) => {
        return u.loyalty_id >= 0;
      });
      return event.length > 0;
    },
    hasEvent(profile) {
      let event = profile.linckards.filter((u) => {
        return u.event_id >= 0;
      });
      return event.length > 0;
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    sobreElements() {
      let def = [];
      def.push(
        {
          nombre: i18n.t("drags.titulo"),
          compo: "editTitulo",
          weight: "500",
          size: "28px",
          lineh: "28px",
          color: "#005a9a",
          family: "Oswald",
          img: "",
          tipo: "freemium",
          design: true,
        },
        {
          nombre: i18n.t("drags.parrafo"),
          compo: "editParrafo_new",
          size: "13px",
          weight: "400",
          lineh: "20px",
          color: "#494949",
          family: "Open Sans",
          img: "",
          tipo: "freemium",
          design: true,
        },
        {
          nombre: i18n.t("drags.boton"),
          compo: "editBoton",
          weight: "400",
          size: "16px",
          lineh: "63px",
          color: "white",
          background: "#3abcfc",
          family: "Oswald",
          img: "",
          tipo: "freemium",
          design: true,
        },
        {
          nombre: i18n.t("drags.space"),
          compo: "editSpace",
          size: "30px",
          tipo: "freemium",
          design: false,
        },
        {
          nombre: i18n.t("drags.imagen"),
          compo: "editImagen",
          family: "Oswald",
          img: "",
          tipo: "freemium",
          design: false,
        },
        {
          nombre: i18n.t("drags.social"),
          compo: "editSocial",
          family: "Oswald",
          size: "25px",
          img: "",
          color: "#9B9B9B",
          img: "",
          tipo: "freemium",
          design: true,
        },
        {
          nombre: i18n.t("drags.avatar"),
          compo: "editAvatar",
          family: "Oswald",
          img: "",
          color: "white",
          img: "",
          tipo: "freemium",
          design: false,
        },
        {
          nombre: i18n.t("drags.line"),
          compo: "editLine",
          size: "1px",
          weight: "4px",
          color: "#9B9B9B",
          family: "Oswald",
          img: "",
          tipo: "freemium",
          design: false,
        },
        {
          dragIcon: 'Lead',
          dragLabel: 'Leads',
          nombre: 'Leads',
          compo: "editBoton",
          family: "Oswald",
          tipo: "pro",
          color: "#FFFFFF",
          background: "#3abcfc",
          icon: 'ph:user-gear-thin',
          value: "Lead capture",
          type: 'form',
        },
        {
          nombre: i18n.t("drags.video"),
          compo: "editVideo",
          family: "Oswald",
          img: "",
          tipo: "pro",
          design: false,
        },
        {
          nombre: i18n.t("drags.ubicacion"),
          compo: "editUbicacion",
          family: "Oswald",
          img: "",
          tipo: "pro",
          design: false,
        },
        {
          nombre: i18n.t("drags.code"),
          compo: "editHTML",
          family: "Oswald",
          img: "",
          tipo: "pro",
          design: false,
        },
        {
          nombre: 'QRCode',
          compo: "editQRCode",
          family: "Oswald",
          img: "",
          tipo: "pro",
          design: false,
        },
        {
          nombre: 'Save the Date',
          compo: "editSaveDate",
          family: "Oswald",
          img: "",
          tipo: "pro",
          design: false,
        },
        {
          nombre: 'Icons',
          compo: "editFlags",
          family: "Oswald",
          img: "",
          tipo: "pro",
          design: false,
          flags: ['ph:question-light'],
          size: 128
        },
        {
          nombre: 'VCF',
          compo: "editVCF",
          family: "Oswald",
          img: "",
          tipo: "beta",
          design: false,
        },
        {
          dragIcon: 'Name',
          dragLabel: 'Name',
          nombre: i18n.t("drags.titulo"),
          compo: "editTitulo",
          weight: "500",
          size: "28px",
          lineh: "28px",
          color: "#FFFFFF",
          family: "Oswald",
          img: "",
          tipo: "freemium",
          design: true,
          value: '{name}',
          tipo: "beta",
        },
      );
      return def;
    },
    sanitizar(url, type) {
      if(url === '' || url === ' '){
        url = null
      }
      if (!url || url == "#") return "";
      if (!url.includes("http")) url = "http://" + url;
      if (url.includes("#http")) url = url.replace("#http", "http");
      else if (type == "xai") url = new URL(url, "https://calendar.x.ai/");
      else if (type == "whereby") url = new URL(url, "https://whereby.app/");
      else if (type == "facebook") url = new URL(url, "https://facebook.com/");
      else if (type == "twitter") url = new URL(url, "https://twitter.com/");
      else if (type == "youtube") url = new URL(url, "https://youtube.com/");
      else if (type == "linkedin") url = new URL(url, "https://linkedin.com/");
      else if (type == "instagram")
        url = new URL(url, "https://instagram.com/");
      else if (type == "vimeo") url = new URL(url, "https://vimeo.com/");
      else if (type == "behance") url = new URL(url, "https://behance.net/");
      else if (type == "tiktok") url = new URL(url, "https://tiktok.com/");
      else if (type == "github") url = new URL(url, "https://github.com/");
      else if (type == "spotify") url = new URL(url, "https://spotify.com/");
      else if (type == "snapchat")
        url = new URL(url, "https://www.snapchat.com/add/");
      else if (type == "googlemaps")
        url = new URL(url, "https://maps.google.com");
      else if (type == "skype") url = new URL(url, "https://skype");
      return url.href ? url.href : url;
    },

    bodyHasDiv(div) {
      if (process.client) {
        const el = document.body.classList;
        if (el) return el.contains(div);
        return false;
      }
    },
    checkLoyaltyCode(password, objMain, loyaltys, index = 0) {
      password = String(password);
      if (password.length < 6) {
        alert("At least 6 characters!");
        return false;
      }

      //Agentes de otros
      for (let i in loyaltys) {
        let findedMain = loyaltys[i];
        if (findedMain.id != objMain.id) {
          for (let ij in findedMain.agents) {
            if (findedMain.agents[ij].code == password) {
              alert("One of the passwords is already used!!");
              console.log(objMain.agents[ij].code, password);
              return false;
            }
          }
        }
      }
      for (let ij in objMain.agents) {
        if (objMain.agents[ij].code == password && ij != index) {
          alert("One of the passwords is already used!!!");
          return false;
        }
      }

      return true;
    },
    toggleDiv(div) {
      const el = document.body.classList;
      if (el && el.contains(div)) el.remove(div);
      else if (el && el.contains("leftcol_open")) el.remove("leftcol_open");
      else if (el && el.contains("search_open")) el.remove("search_open");
      else if (el && el.contains("rightcol_open")) el.remove("rightcol_open");
      else if (el && el.contains("menu_open")) el.remove("menu_open");
      else if (el) el.add(div);
      if (div == "leftcol_open") el.add("overlay_visible");
    },
    closeAllDivs() {
      const el = document.body.classList;
      if (el && el.contains("leftcol_open")) el.remove("leftcol_open");
      if (el && el.contains("search_open")) el.remove("search_open");
      if (el && el.contains("rightcol_open")) el.remove("rightcol_open");
      if (el && el.contains("menu_open")) el.remove("menu_open");
      else return false;
      return true;
    },
    getCurrentSubscription(profile, type) {
      console.log(profile, type);
      let sub = profile.stripe_details.stripe_subscriptions.find((e) => {
        return e.type == type;
      }); //encuentro la sub id por type a reemplazar
      if (!sub || isEmptyObject(sub)) {
        this.notif_error("Error in your subscription, please contact support");
      }
      return sub;
    },
    getPlanesByType(type) {
      return planes_businesscards;
      /*
            if(type=='bc')return planes_businesscards
            else if(type=='loyalty')return planes_loyalty*/
    },
    getCurrentProduct(profile, type) {
      let sub = this.getCurrentSubscription(profile, type);
      if (!sub) return { name: "Trial" };
      let prod_id = sub.prod_id;
      let planes = this.getPlanesByType(type);

      for (let p in planes) {
        let product = planes[p];
        if (product.prod_id == prod_id) return product;
      }
      return "none";
    },
    isPlanInit(profile) {
      return profile.stripe_details.stripe_prod_id.length == 0;
    },

    hasBCData(cardVal) {
      return (
        cardVal.info.nombre &&
        cardVal.info.trabajo &&
        (this.getSobreImagen(cardVal) || this.getLincImage(cardVal))
      );
    },
    verificarLinkcard(
      linckard,
      log = false,
      profile = store.state.userProfile
    ) {
      let entra = false;
      if (profile.isFullEdit) return true;
      let msg = i18n.t("notis.falta1") + "<br>";
      let layout = linckard.info.clayout;
      if (linckard.isLanding) {
        if (
          !linckard.info.nombre_landing ||
          (linckard.info.nombre_landing &&
            linckard.info.nombre_landing.length == 0)
        ) {
          entra = true;
          if (log) msg += i18n.t("info.nombreland") + "<br>";
        }
      } else if (linckard.loyalty_id >= 0) {
        if (!linckard.membre.img) {
          entra = true;
          if (log) msg += i18n.t("info.imagenp") + "<br>";
        }
        if (linckard.membre.nombre.length == 0) {
          entra = true;
          if (log) msg += i18n.t("info.nombrecc") + "<br>";
        }
        if (linckard.membre.dni == 0) {
          entra = true;
          if (log) msg += i18n.t("info.dni") + "<br>";
        }
        if (!linckard.membre.birthday || linckard.membre.birthday == 0) {
          entra = true;
          if (log) msg += i18n.t("info.birthday") + "<br>";
        }
        if (
          !linckard.membre.mobilephone ||
          linckard.membre.mobilephone.length == 0
        ) {
          entra = true;
          if (log) msg += i18n.t("info.telmovil") + "<br>";
        }
      } else {
        if (!linckard.info.img) {
          entra = true;
          if (log) msg += i18n.t("info.imagenp") + "<br>";
        }
        if (linckard.info.nombre && linckard.info.nombre.length == 0) {
          entra = true;
          if (log) msg += i18n.t("info.nombrecc") + "<br>";
        }
        if (linckard.info.trabajo.length == 0) {
          entra = true;
          if (log) msg += i18n.t("info.profes") + "<br>";
        }
        if (
          linckard.info.mobilephone &&
          linckard.info.mobilephone.length == 0 &&
          [1, 2, 3, 4, 5, 9].includes(layout)
        ) {
          entra = true;
          if (log) msg += i18n.t("info.telmovil") + "<br>";
        }
        if (
          linckard.info.phone &&
          linckard.info.phone.length == 0 &&
          [3, 4, 6].includes(layout)
        ) {
          entra = true;
          if (log) msg += api18np.t("info.telfijo") + "<br>";
        }
        if (linckard.info.email.length == 0) {
          entra = true;
          if (log) msg += i18n.t("info.correoelec") + "<br>";
        }
        if (
          linckard.info.url &&
          linckard.info.url.length == 0 &&
          [7, 8].includes(layout)
        ) {
          entra = true;
          if (log) msg += "Website" + "<br>";
        }
      }
      if (entra) {
        if (log) this.notif_error(msg);
        return false;
      } else return true;
    },
    forceHTTPS(url) {
      if (!url.includes("http")) url = "https://" + url;
      return "https://" + new URL(url).hostname + "/";
    },
    cardURL(profile = store.state.userProfile, linc) {
      //console.log('custom_dmain',profile.account_details.account_custom_domain,linc?linc.custom_domain:'')
      if (profile && profile.isCDValid && linc && linc.custom_domain)
        return this.forceHTTPS(linc.custom_domain);
      else if (
        profile &&
        profile.isCDValid &&
        profile.account_details.account_custom_domain
      )
        return this.forceHTTPS(profile.account_details.account_custom_domain);
      else if (process.env.AMBIENTE == "development")
        return "https://linkcard-testing.web.app/";
      else if (
        process.env.AMBIENTE == "production" &&
        process.env.TYPE == "cloud"
      )
        return "https://m.pge.link/";
      else return process.env.CARDURL; // 'http://localhost:4000/'
    },
    minLineh(size, line) {
      return line;
      if (!size || !line) return;
      size = parseInt(size.replace("px", ""));
      line = parseInt(line.replace("px", ""));
      if (line < size + 4) line = parseInt(size + 6);
      console.log(size, line);
      return line + "px";
    },
    parseVariables(value) {
      if (!value || !value.info) return value;
      let valuestr = JSON.stringify(value);
      valuestr = valuestr.replaceAll("{name}", value.info.nombre);
      valuestr = valuestr.replaceAll("{job}", value.info.trabajo);
      valuestr = valuestr.replaceAll("{email}", value.info.email);
      valuestr = valuestr.replaceAll("{calendar}", value.info.calendar);
      valuestr = valuestr.replaceAll("{city}", value.info.city);
      valuestr = valuestr.replaceAll("{state}", value.info.state);
      valuestr = valuestr.replaceAll("{country}", value.info.country);
      valuestr = valuestr.replaceAll("{street}", value.info.street);
      valuestr = valuestr.replaceAll("{postal_code}", value.info.postal_code);
      valuestr = valuestr.replaceAll("{address}", value.info.address);
      valuestr = valuestr.replaceAll("{pcode}", value.info.pcode);
      valuestr = valuestr.replaceAll("{website}", value.info.website);
      valuestr = valuestr.replaceAll("{mobilephone}", value.info.mobilephone);
      valuestr = valuestr.replaceAll("+1 1111111111", value.info.mobilephone);
      if (!value.info.mobilephone && value.info.phone)
        valuestr = valuestr.replaceAll("+1 1111111111", value.info.phone);
      valuestr = valuestr.replaceAll("{fixphone}", value.info.phone);
      valuestr = valuestr.replaceAll("{phone}", value.info.phone);
      valuestr = valuestr.replaceAll("+1 2222222222", value.info.phone);
      valuestr = valuestr.replaceAll(
        "{mobilephone_code}",
        value.info.mobilephone_code
      );
      valuestr = valuestr.replaceAll("{phone_code}", value.info.phone_code);
      valuestr = valuestr.replaceAll("{twitter}", value.info.twitter);
      valuestr = valuestr.replaceAll("{youtube}", value.info.youtube);
      valuestr = valuestr.replaceAll("{telegram}", value.info.telegram);
      valuestr = valuestr.replaceAll("{line}", value.info.line);
      valuestr = valuestr.replaceAll("{spotify}", value.info.spotify);
      valuestr = valuestr.replaceAll("{snapchat}", value.info.snapchat);
      valuestr = valuestr.replaceAll("{linkedin}", value.info.linkedin);
      valuestr = valuestr.replaceAll("{instagram}", value.info.instagram);
      valuestr = valuestr.replaceAll("{facebook}", value.info.facebook);
      valuestr = JSON.parse(valuestr);
      if (
        valuestr.sobredrags &&
        valuestr.sobredrags[0] &&
        valuestr.sobredrags[0].compo == "editAvatar" &&
        valuestr.sobredrags[0].img == null
      )
        valuestr.sobredrags[0].img = value.info.img;
      else if (
        valuestr.sobredrags &&
        valuestr.sobredrags[0] &&
        valuestr.sobredrags[0].compo == "editSpace" &&
        valuestr.sobredrags[1].compo == "editAvatar" &&
        valuestr.sobredrags[1].img == null
      )
        valuestr.sobredrags[1].img = value.info.img;
      return valuestr;
    },
    getvideo(val) {
      if (!val) return "";
      console.log(val);
      if (val.includes("vimeo")) {
        var video_id = val.split("/")[3];
        return "https://player.vimeo.com/video/" + video_id;
      } else if (val.includes("shorts")) {
        var video_id = val.split("shorts/")[1];
        var ampersandPosition = video_id.indexOf("&");
        if (ampersandPosition != -1)
          video_id = video_id.substring(0, ampersandPosition);
        return "https://www.youtube.com/embed/" + video_id;
      } else if (val.includes("youtube")) {
        var video_id = val.split("v=")[1];
        var ampersandPosition = video_id.indexOf("&");
        if (ampersandPosition != -1)
          video_id = video_id.substring(0, ampersandPosition);
        return "https://www.youtube.com/embed/" + video_id;
      } else if (val.includes("youtu.be")) {
        var video_id = val.split("/")[val.split("/").length - 1];
        return "https://www.youtube.com/embed/" + video_id;
      }
    },
    getVideoTourID() {
      let idvideo = i18n.locale == "es" ? "i3Gp9MFztvY" : "lHmYsoaC8QE";
      return idvideo;
    },
    getSignatureVideoID() {
      let idvideo = i18n.locale == "es" ? "dyv6UVK_S7g" : "HKFhmrYGOHk";
      return idvideo;
    },
    getURL_Blog() {
      let locale = i18n.locale == "en" ? "en" : "";
      return "https://blog.linkcard.app/" + locale;
    },
    getURL_Formsales() {
      let locale = i18n.locale == "en" ? "en" : "";
      return "https://blog.linkcard.app/" + locale + "/enterprise-form/";
    },
    cookieURL(type = this.getMode()) {
      let locale = store.state.locale == "en" ? "en" : "";
      locale = "en";
      return "https://blog.linkcard.app/" + locale + "/cookie-policy/";
    },
    termsURL(type = this.getMode()) {
      let locale = store.state.locale == "en" ? "en" : "";
      locale = "en";
      return "https://blog.linkcard.app/" + locale + "/terms-of-service/";
    },
    privacyURL(type = this.getMode()) {
      let locale = store.state.locale == "en" ? "en" : "";
      locale = "en";
      return "https://blog.linkcard.app/" + locale + "/privacy";
    },
    landingURL(type = this.getMode()) {
      if (process.env.AMBIENTE == "development")
        return "https://linkcard-testing.web.app/";
      else {
        if (type == "loyalty")
          return "https://linkcard.app/loyalty?lang=" + store.state.locale;
        else return "https://linkcard.app/?lang=" + store.state.locale;
      }
    },
    initLoyalty(objMain, profile, prebenefits = "points") {
      if (!objMain) {
        //Onboarding
        objMain = {};
      }
      objMain.id = null;
      objMain.name = "";
      objMain.desc = "";
      objMain.price = 10;
      objMain.point = 1;

      objMain.loyalty_from = profile.uid;
      objMain.c_members = 0;
      objMain.locale = i18n.locale;
      objMain.location = "";
      (objMain.password = this.randomchar()),
        (objMain.date_created = this.getDate());
      objMain.init_points = 0;
      objMain.agents = [];
      objMain.agents.push({ id: 0, label: "Main", code: objMain.password });
      objMain.round = true;
      objMain.suspend = true;
      objMain.ticket = true;
      objMain.status = "active";

      if (!profile.stats.c_loyaltys) profile.stats.c_loyaltys = 0;
      objMain.id = profile.stats.c_loyaltys++;
      objMain.linckard = JSON.parse(JSON.stringify(linckard_model));
      objMain.linckard = this.styleLoyaltyLinkcard(
        objMain.linckard,
        objMain.id
      );
      objMain.linckard.membre = {
        img: "",
      };
      objMain.linckard.loyalty_id = objMain.id;
      objMain.linckard.info.img = profile.account_details.account_picture;

      if (prebenefits == "points") {
        objMain.benefits = [
          {
            id: 1,
            points: 10,
            name: "15% " + i18n.t("loyaltys.descuento"),
            status: "active",
            desc:
              i18n.t("loyaltys.descuento1") +
              " 15% " +
              i18n.t("loyaltys.descuento2"),
          },
          {
            id: 2,
            points: 20,
            name: "25% " + i18n.t("loyaltys.descuento"),
            status: "active",
            desc:
              i18n.t("loyaltys.descuento1") +
              " 25% " +
              i18n.t("loyaltys.descuento2"),
          },
          {
            id: 3,
            points: 30,
            name: "35% " + i18n.t("loyaltys.descuento"),
            status: "active",
            desc:
              i18n.t("loyaltys.descuento1") +
              " 35% " +
              i18n.t("loyaltys.descuento2"),
          },
          {
            id: 4,
            points: 40,
            name: "45% " + i18n.t("loyaltys.descuento"),
            status: "active",
            desc:
              i18n.t("loyaltys.descuento1") +
              " 45% " +
              i18n.t("loyaltys.descuento2"),
          },
        ];
      } else {
        objMain.nopoints = true;
      }
      return objMain;
    },
    getLocale() {
      let locale = "es";
      if (route.query.lang) {
        locale = route.query.lang;
      } else {
        locale = this.getBrowserLang();
      }
      return this.parseLocale(locale);
    },
    initPage(
      dirUser,
      manifestURL,
      admin = false,
      validator,
      overridelocale = false,
      disablechat = false,
      disablefontload = false
    ) {
      //Fonts)
      if (!disablefontload) {
        var WebFont = require("webfontloader");
        if (admin) {
          WebFont.load({ google: { families: store.state.admin_families } });
        } else {
          WebFont.load({ google: { families: store.state.families } });
        }
      }

      //locale
      let locale = "es";
      if (dirUser) {
        locale = dirUser.account_details.account_locale;
      } else if (route.query.lang) {
        locale = route.query.lang;
      } else if (overridelocale) {
        locale = overridelocale;
      } else {
        locale = this.getBrowserLang();
      }

      Settings.defaultLocale = locale;

      if (manifestURL) this.linkManifest(manifestURL);

      /*if(window && window.fcWidget && dirUser && !disablechat){
              //if(dirUser){   
                window.fcWidget.init({
                  token: "d65e1891-eb5f-43f0-a73f-7aabf949b7d6",
                  host: "https://wchat.freshchat.com",
                  firstName:dirUser.account_details.account_name,
                  externalId:dirUser.email})
              //}else{
               // window.fcWidget.init({token: "d65e1891-eb5f-43f0-a73f-7aabf949b7d6",host: "https://wchat.freshchat.com"})
              //}
            }*/

      this.setLocale(locale, validator);
      i18n.locale = locale;
      store.commit("SET_LANG", locale);
    },

    setLocale(locale, validator) {
      if (process.client && store.state.locales.includes(locale)) {
        store.state.locale = locale;
        i18n.locale = locale;
        if (validator) validator.locale = locale;
      }
    },
    hasOneMembership() {
      if (store.state.userProfile) return false;
      let linc = store.state.userProfile.linckards;
      for (let i in linc) {
        if (linc[i].loyalty_id >= 0) return true;
      }
    },
    linkManifest(manifestURL) {
      if (!manifestURL) manifestURL = process.env.URL + "manifest.json";
      var sc = document.createElement("link");
      sc.setAttribute("href", manifestURL);
      sc.setAttribute("rel", "manifest");
      sc.setAttribute("type", "application/manifest+json");
      document.head.appendChild(sc);
    },
    async removePartnershipOK(partner, user) {
      var dis = this;
      if (!partner && user.hasAccess)
        partner = await dis.dbgetUser(user.hasAccess.uid);

      user.hasAccess = { uid: null, img: null };
      var elindex = partner.companies.findIndex(function(i) {
        return i.uid == user.uid;
      });
      partner.companies.splice(elindex, 1);

      db.collection("users")
        .doc(partner.uid)
        .update({ companies: partner.companies })
        .then(function() {
          db.collection("users")
            .doc(user.uid)
            .update({ hasAccess: user.hasAccess });
          dis.notif_success(i18n.t("notis.cremove"));
          //bus.$emit('partner_newAccess',user)
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    },

    alreadyReviewed(partner, user) {
      if (!partner.reviews || !partner.reviews.users) return false;
      let index = partner.reviews.users.findIndex(function(i) {
        return i.uid == user.uid;
      });
      return index >= 0;
    },
    async removePartnership(modal, partner, user) {
      var dis = this;

      if (!this.alreadyReviewed(partner, user)) {
        let msg = store.state.userProfile.isPartner
          ? i18n.t("notis.revokewr")
          : i18n.t("notis.revokefromwr");
        modal.show("dialog", {
          title: i18n.t("notis.atencion"),
          text: msg,
          buttons: [
            {
              title: i18n.t("notis.noyes"),
              handler: () => {
                dis.removePartnershipOK(partner, user);
                modal.hide("dialog");
              },
            },
            {
              title: i18n.t("notis.leave"),
              handler: () => {
                modal.hide("dialog");
                modal.show("modal-review", { profile: partner });
              },
            },
            { title: i18n.t("general.cerrar") },
          ],
        });
      } else {
        let msg = store.state.userProfile.isPartner
          ? i18n.t("notis.revoke")
          : i18n.t("notis.revokefrom");
        modal.show("dialog", {
          title: i18n.t("notis.atencion"),
          text: msg,
          buttons: [
            {
              title: i18n.t("notis.si"),
              handler: () => {
                dis.removePartnershipOK(partner, user);
                modal.hide("dialog");
              },
            },
            { title: i18n.t("general.cerrar") },
          ],
        });
      }
    },
    hideAllPopups() {
      var elements = document.getElementsByClassName("card_dd_menu");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
    },
    getActiveMembersByLoyalty(profile, loyalty_id) {
      var dis = this;
      let members = profile.loyaltys.find(function(e) {
        return e.id == loyalty_id;
      });
      return getActiveMembers(members).length;
    },
    isMemberActive(last_transaction, month) {
      return helpers.isMemberActive(last_transaction, month);
    },
    getActiveMembers(members) {
      return helpers.getActiveMembers(members);
    },
    normalizarBusqueda(text) {
      return helpers.normalizarBusqueda(text);
    },
    getLinkcardURL(username, id) {
      if (process.client) {
        let url = this.cardURL() + "/" + username + "/" + id;
        return url;
      }
    },
    getEventsMonth(events) {
      var dis = this;
      if (!events) return;
      return events.filter((u) => {
        var today = dis.getDate();
        today = new Date(
          today.split("/")[2],
          today.split("/")[1] - 1,
          today.split("/")[0]
        );
        var date2 = u.created_date;
        if (!date2) date2 = dis.getDate();
        date2 = new Date(
          date2.split("/")[2],
          date2.split("/")[1] - 1,
          date2.split("/")[0]
        );
        var timeDiff = Math.abs(date2.getTime() - today.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return diffDays <= 30;
      }).length;
    },
    async dbgetUserByUsername(username) {
      //getuserdb fromdb
      var dis = this;
      return await db
        .collection("users")
        .where("username", "==", username)
        .get()
        .then(function(querySnapshot) {
          //console.log('vuelve: '+querySnapshot.size)
          if (querySnapshot.size == 0) {
            dis.loading_12 = false; //cambiar
            //console.log('No existe en db')
          } else if (querySnapshot.size == 1) {
            for (var i in querySnapshot.docs) {
              const doc = querySnapshot.docs[i];
              let getUser = doc.data();
              return getUser;
            }
          }
        })
        .catch(function(error) {
          dis.notif_error(error.message);
        });
    },

    async dbgetUserByUid(uid) {
      //getuserdb fromdb
      let user = await db
        .collection("users")
        .doc(uid)
        .get();
      return user.data();
    },
    async dbgetUserByEmail(email) {
      //getuserdb fromdb
      email = email.toLowerCase();
      var dis = this;
      return await db
        .collection("users")
        .where("email", "==", email)
        .get()
        .then(function(querySnapshot) {
          if (querySnapshot.size == 0) {
          } else if (querySnapshot.size == 1) {
            const doc = querySnapshot.docs[0];
            let getUser = doc.data();
            return getUser;
          } else {
            console.log("Este user tiene duplicados en DB");
            for (var i in querySnapshot.docs) {
              const doc = querySnapshot.docs[i];
              let getUser = doc.data();
            }
          }
        })
        .catch(function(error) {
          dis.notif_error(error.message);
        });
    },
    async dbgetUser(uid) {
      //getuserdb fromdb
      var dis = this;
      return db
        .collection("users")
        .doc(uid)
        .get()
        .then(function(doc) {
          let user = doc.data();
          return user;
        })
        .catch(function(error) {
          dis.notif_error(error.message);
        });
    },
    changePassword() {
      var dis = this;
      var newPassword = window.prompt(i18n.t("login.newpass"));
      if (!newPassword) return;
      auth.currentUser
        .updatePassword(newPassword)
        .then(function() {
          dis.notif_success();
        })
        .catch(function(error) {
          dis.notif_error(error.message);
        });
    },
    forgotPassword() {
      var dis = this;
      var emailAddress = window.prompt(i18n.t("login.generar"));
      if (!emailAddress) return;
      var actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: false,
      };
      auth
        .sendPasswordResetEmail(emailAddress, actionCodeSettings)
        .then(function() {
          dis.notif_success(i18n.t("notis.validation"));
        })
        .catch(function(error) {
          dis.notif_error(error.message);
        });
    },
    async getImageUrl(toUpload, round = false) {
      var dis = this;

      if (toUpload == undefined) return null;
      if (!toUpload || typeof toUpload !== "object") return null; //saco la image de upload logo del template
      this.notif_info(i18n.t("notis.filebup"));
      let filePath = toUpload.imgid + "/" + toUpload.id; //graph fi
      let file = toUpload.file;
      if (round) {
        filePath += "_round";
        file = toUpload.file_round;
      }
      let url = await this.upload(file, filePath);
      return dis.removeToken(url, null);
    },
    hayBarra() {
      return false;
      if (!this.isInTrial()) return false;
      if (cookies.get("__sacarbarra")) return false;
      let barra = "";
      if (i18n.locale == "en")
        barra =
          '<span>You have 15 days to discover all Linkcard\'s potential after which some features will require an upgrade from <a  href="/account#plans">My Account</a> section</span>';
      else
        barra =
          '<span>Tienes 15 días para probar todo el potencial de Linkcard. Luego tendrás que hacer un upgrade para ciertas funciones desde <a  href="/account#plans">Mi Cuenta</a></span>';
      return barra;
    },
    async upload(file, filePath) {
      let small = filePath.includes("background") ? false : true;
      let storageref = small ? storagesmall : storagelarge;
      var uploadTask = storageref
        .ref()
        .child(filePath)
        .put(file, { contentType: file.type });
      return uploadTask
        .then((snap) => {
          return snap.ref.getDownloadURL();
        })
        .then((downloadURL) => {
          console.log(downloadURL);
          return downloadURL;
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
    },
    async getDriveFile(remoteimageurl, gapi_util) {
      remoteimageurl = remoteimageurl.replace(
        "https://drive.google.com/file/d/",
        ""
      );
      remoteimageurl = remoteimageurl.replace("/view", "");
      let blob = "";
      let ggapi = await gapi_util.getGapiClient().then(async (gapi) => {
        return gapi;
      });
      console.log(gapi_util, ggapi);
      return gapi_util.client.drive.files
        .get({
          fileId: remoteimageurl,
          alt: "media",
        })
        .then(function(res) {
          blob = new Blob([res.body]);
          blob = blob.slice(0, blob.size, "image/jpeg");
          //blob['type']='image/jpeg'
          return blob;
        })
        .catch(async (err) => {
          console.log(err);
          return null;
        });
    },
    async uploadfUrl(remoteimageurl, gapi) {
      let blob = "";
      var filename = this.randomchar();
      try {
        if (!remoteimageurl) return null;
        if (remoteimageurl.includes("drive.google")) {
          blob = await this.getDriveFile(remoteimageurl, gapi);
          console.log(blob);
        } else if (remoteimageurl.includes("firebase")) {
          return remoteimageurl;
        } else {
          const response = await fetch(remoteimageurl);
          blob = await response.blob();
        }
        let url = await storagesmall
          .ref()
          .child(filename)
          .put(blob)
          .then(function(snapshot) {
            return snapshot.ref.getDownloadURL();
          });
        console.log(this.removeToken(url));
        return this.removeToken(url);
      } catch (e) {
        console.log(e);
      }
    },
    isStandAlone() {
      if (process.client) {
        let isInWebAppiOS = window.navigator.standalone == true;
        let isInWebAppChrome = window.matchMedia("(display-mode: standalone)")
          .matches;
        return isInWebAppiOS || isInWebAppChrome;
      }
      return false;
    },
    isLogged() {
      //console.log('essss:',store.state.currentUser)
      return store.state.currentUser;
    },
    isAnonymous() {
      return auth.currentUser.isAnonymous;
    },
    overriddenNetworks(url) {
      let devolver = {
        messenger: {
          sharer: "fb-messenger://share/?link=" + url + "?app_id=123456789",
          type: "_blank",
        },

        twtweb: {
          sharer:
            "https://twitter.com/intent/tweet?text=" +
            i18n.t("share.title") +
            "&url=" +
            url +
            "&hashtags=linkcard,business,card,tool&via=linckard",
          target: "_blank",
        },
        wpweb: {
          sharer:
            "https://api.whatsapp.com/send?text=" +
            i18n.t("share.desc") +
            " " +
            url,
          target: "_blank",
        },
        whatsappnative: {
          sharer: "whatsapp://send?text=" + i18n.t("share.desc") + " " + url,
          target: "_blank",
        },
        smsweb: {
          sharer: "sms:&amp;body=" + i18n.t("share.desc") + " " + url,
          target: "_blank",
        },
        wechat: {
          sharer:
            "weixin://dl/chat?&amp;body=" + i18n.t("share.desc") + " " + url,
          target: "_blank",
        },
      };
      return JSON.parse(JSON.stringify(devolver));
    },
    shareOn(who, url) {
      let newlink = "";
      if (who == "messenger")
        newlink = "fb-messenger://share/?link=" + url + "?app_id=123456789";
      else if (who == "whatsapp-web")
        newlink =
          "https://api.whatsapp.com/send?text=" +
          i18n.t("share.desc") +
          " " +
          url;
      else if (who == "whatsapp")
        newlink = "whatsapp://send?text=" + i18n.t("share.desc") + " " + url;
      window.open(newlink, "_blank");
    },
    detectBrowser() {
      // Opera 8.0+
      if (window) {
        var isOpera =
          (!!window.opr && !!opr.addons) ||
          !!window.opera ||
          navigator.userAgent.indexOf(" OPR/") >= 0;
        if (isOpera) return "opera";
        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== "undefined";
        if (isFirefox) return "firefox";
        // Safari 3.0+ "[object HTMLElementConstructor]"
        var isSafari =
          /constructor/i.test(window.HTMLElement) ||
          (function(p) {
            return p.toString() === "[object SafariRemoteNotification]";
          })(
            !window["safari"] ||
              (typeof safari !== "undefined" && safari.pushNotification)
          );

        if (isSafari || this.isIOSSafari()) return "safari";
        // Internet Explorer 6-11
        var isIE = /*@cc_on!@*/ false || !!document.documentMode;
        if (isIE) return "ie";
        // Edge 20+
        var isEdge = !isIE && !!window.StyleMedia;
        if (isEdge) return "edge";
        // Chrome 1 - 79
        var isChrome =
          !!window.chrome &&
          (!!window.chrome.webstore || !!window.chrome.runtime);
        if (isChrome || this.isAndroid()) return "chrome";
        // Edge (based on chromium) detection
        var isEdgeChromium =
          isChrome && navigator.userAgent.indexOf("Edg") != -1;
        if (isEdgeChromium) return "edgechromium";
        // Blink engine detection
        var isBlink = (isChrome || isOpera) && !!window.CSS;
        if (isBlink) return "blink";

        return "unknown";
      }
    },
    isApple() {
      if (window) {
        return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
      }
    },
    isIOSSafari() {
      if (window) {
        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        return iOSSafari;
      }
      return families;
    },
    isAndroid() {
      if (window) {
        var ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("android") > -1;
      }
      return false;
    },
    isMobile() {
      if (process.server) return false;
      var check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    notif_info(msg) {
      if (!msg.includes("Generado")) store.state.log = msg;
      Vue.notify({ group: "notis", type: "info", title: "INFO", text: msg });
    },
    notif_success(msg) {
      store.state.log = msg;
      if (msg === undefined || msg == null) msg = "Success!";
      Vue.notify({
        group: "notis",
        type: "success",
        title: "SUCCESS",
        text: msg,
      });
    },
    notif_error(msg) {
      store.state.log = msg;
      if (msg === undefined || msg == null) msg = "Error!";
      Vue.notify({ group: "notis", type: "error", title: "ERROR", text: msg });
    },
    isSubLinckard(obj) {
      return (
        obj.stats &&
        obj.stats.cant_linkcards_active &&
        obj.stats.cant_linkcards_active == 1 &&
        obj.linckards &&
        obj.linckards.length == 1 &&
        !obj.linckards[0].style
      );
    },
    isFav(profile, uid) {
      if (!profile.contacts || profile.contacts.refs) profile.contacts = [];
      let contactsFind = profile.contacts.filter((u) => {
        return u.uid == uid;
      });
      let find = false;
      contactsFind.forEach((user) => {
        if (!this.isSubLinckard(user)) find = true;
      });
      return find;
    },
    isLincFav(profile, uid, linc_id) {
      if (!profile) return false;
      if (!profile.contacts || profile.contacts.refs) profile.contacts = [];
      let contactsFind = profile.contacts.filter((u) => {
        return u.uid == uid;
      });
      let find = false;
      for (let user of contactsFind) {
        if (this.isSubLinckard(user)) {
          let linc = user.linckards[0];
          if (linc.id == linc_id) {
            find = true;
            break;
          }
        }
      }
      return find;
    },
    addLincFav(modal, profile, contact, linc_id) {
      if (this.isProNetworking(modal, profile)) {
        if (!this.isLincFav(profile, contact.uid, linc_id)) {
          this.saveFav(profile, contact, linc_id);
        }
      }
    },
    addFav(modal, profile, contact) {
      if (this.isProNetworking(modal, profile)) {
        if (!this.isFav(profile, contact.uid)) {
          this.saveFav(profile, contact, null);
        }
      }
    },
    getBrowserLang() {
      let lang = "en";
      if (process.client && navigator) {
        lang = navigator.languages[0] || navigator.userLanguage;
      }
      return lang;
    },
    async saveFav(profile, contact, linc_id) {
      let newContact = this.contactObj(contact, linc_id);
      if (!profile.contacts || profile.contacts.refs) profile.contacts = [];
      profile.contacts.push(newContact);
      if (!profile.denom_contacts) profile.denom_contacts = [];
      if (!profile.denom_contacts.includes(newContact.uid))
        profile.denom_contacts.push(newContact.uid);

      // this.registerStat_old('like',linc_id?'linkcard':'user','directory','bc',contact,linc_id)
      profile = JSON.parse(JSON.stringify(profile));

      //c_contacts
      if (!profile.stats.c_contacts)
        profile.stats.c_contacts = profile.contacts.length;
      else profile.stats.c_contacts++;

      await db
        .collection("users")
        .doc(profile.uid)
        .update({
          stats: profile.stats,
          contacts: profile.contacts,
          denom_contacts: profile.denom_contacts,
        });
    },

    removeFav(profile, uid) {
      var dis = this;
      let finded = profile.contacts.filter((u) => {
        return u.uid == uid;
      });
      let masdeUno = finded.length > 1;

      let index = profile.contacts.findIndex(function(u) {
        return u.uid == uid && !dis.isSubLinckard(u);
      });
      profile.contacts.splice(index, 1);

      if (!masdeUno)
        profile.denom_contacts.splice(profile.denom_contacts.indexOf(uid), 1);

      profile = JSON.parse(JSON.stringify(profile));
      db.collection("users")
        .doc(profile.uid)
        .update({
          contacts: profile.contacts,
          denom_contacts: profile.denom_contacts,
        });
    },
    removeLincFav(profile, uid, linc_id) {
      var dis = this;
      let finded = profile.contacts.filter((u) => {
        return u.uid == uid;
      });
      let masdeUno = finded.length > 1;

      let index = profile.contacts.findIndex(function(u) {
        return (
          u.uid == uid &&
          dis.isSubLinckard(u) &&
          u.linckards &&
          u.linckards[0].id == linc_id
        );
      });

      profile.contacts.splice(index, 1);
      if (!masdeUno)
        profile.denom_contacts.splice(profile.denom_contacts.indexOf(uid), 1);

      profile = JSON.parse(JSON.stringify(profile));
      db.collection("users")
        .doc(profile.uid)
        .update({
          contacts: profile.contacts,
          denom_contacts: profile.denom_contacts,
        });
    },
    getFreemiumPlans() {
      return [planes_businesscards[0]];
      //return [planes_loyalty[0],planes_businesscards[0]]
    },
    cambiarProduct(modal, profile, product, place = "modal") {
      var dis = this;
      if (this.isFreemiumPlan(product)) {
        //Rollback
        //Cancelo cualquier subscritp
        modal.show("dialog", {
          title: i18n.t("notis.atencion"),
          text: i18n.t("notis.downgrade"),
          buttons: [
            {
              title: i18n.t("notis.si"),
              handler: () => {
                modal.hide("dialog");
                modal.show("modal-churn");
              },
            },
            { title: i18n.t("general.cerrar") },
          ],
        });
      } else {
        //Cambio el plan normal
        modal.show("modal-payment", {
          place: place,
          user: profile,
          product: product,
        });
      }
    },
    isBCCustomizer() {
      return !this.isLoyaltyCustomizer();
    },
    isLoyaltyCustomizer() {
      return (
        store.getters.editingLinckard &&
        store.getters.editingLinckard.loyalty_id >= 0
      );
    },
    isCustomizer() {
      return router.history.current.name.includes("editor");
    },
    isSignatureCustomizer() {
      return router.history.current.name.includes("signatures");
    },
    isOnboarding(type = null) {
      let is = router.history.current.name.includes("onboarding");
      if (type) is = is && this.isMODE_ON(type);
      return is;
    },
    directoryOK(profile) {
      return helpers.directoryOK(profile);
    },
    parseSegment(profile = store.state.userProfile) {
      let lasub = profile.stripe_details.stripe_subscriptions.find((e) => {
        return e.type == "bc";
      });
      //if(profile.stripe_details.stripe_subscriptions.length==1)lasub=profile.stripe_details.stripe_subscriptions[0]
      if (!lasub) return "Freemium";
      if (lasub.plan_id == "freemium") lasub.plan_id += "_bc"; //fix
      let old_plans = [
        {
          plan_id: "freemium_bc",
          prod_id: "freemium_bc",
          name: "Freemium",
          type: "BC",
          freq: "Monthly",
          price: 0,
        },
        {
          plan_id: "freemium_event",
          prod_id: "freemium_event",
          name: "Freemium",
          type: "Event",
          freq: "Monthly",
          price: 0,
        },
        {
          plan_id: "freemium_loyalty",
          prod_id: "freemium_loyalty",
          name: "Freemium",
          type: "Loyalty",
          freq: "Monthly",
          price: 0,
        },
        {
          plan_id: "plan_ErmsVq5pRMFs0C",
          prod_id: "prod_F0yelsgoB1F4hx",
          name: "Professional",
          type: "BC",
          freq: "Yearly",
          price: 20.4,
        },
        {
          plan_id: "plan_ErUQwLpngTMmTV",
          prod_id: "prod_F0yelsgoB1F4hx",
          name: "Professional",
          type: "BC",
          freq: "Monthly",
          price: 1.99,
        },
        {
          plan_id: "plan_Ermwa9SrE3JbYn",
          prod_id: "prod_F0yj2vUXAbPHcO",
          name: "Professional",
          type: "BC",
          freq: "Yearly",
          price: 61.1,
        },
        {
          plan_id: "plan_FyQspTzlgIzlfe",
          prod_id: "prod_F0yj2vUXAbPHcO",
          name: "Professional",
          type: "BC",
          freq: "Yearly",
          price: 40.7,
        },
        {
          plan_id: "plan_FyQrWYA3pl48wQ",
          prod_id: "prod_F0yj2vUXAbPHcO",
          name: "Professional",
          type: "BC",
          freq: "Monthly",
          price: 3.99,
        },
        {
          plan_id: "plan_ErmtoMMwlZhkYm",
          prod_id: "prod_F0yj2vUXAbPHcO",
          name: "Professional",
          type: "BC",
          freq: "Monthly",
          price: 5.99,
        },
        {
          plan_id: "plan_GzkJlltyuVfYFo",
          prod_id: "prod_GzkIZiOQFGgB0c",
          name: "Professional",
          type: "BC",
          freq: "Yearly",
          price: 45,
        },
        {
          plan_id: "plan_GzkIPO0Q8ki3Am",
          prod_id: "prod_GzkIZiOQFGgB0c",
          name: "Professional",
          type: "BC",
          freq: "Monthly",
          price: 5,
        },
        {
          plan_id: "plan_GzkHfZQHVqMFLZ",
          prod_id: "prod_F0yj2vUXAbPHcO",
          name: "Professional",
          type: "BC",
          freq: "Yearly",
          price: 144,
        },
        {
          plan_id: "plan_GzkHtr7Zb8zcqe",
          prod_id: "prod_F0yj2vUXAbPHcO",
          name: "Professional",
          type: "BC",
          freq: "Monthly",
          price: 16,
        },
        {
          plan_id: "plan_ErmuZz3KWp54A2",
          prod_id: "prod_F0ymHDps8vXC8E",
          name: "Business",
          type: "BC",
          freq: "Yearly",
          price: 101.99,
        },
        {
          plan_id: "plan_FyQpEtIfqf6q5w",
          prod_id: "prod_F0ymHDps8vXC8E",
          name: "Business",
          type: "BC",
          freq: "Yearly",
          price: 234.5,
        },
        {
          plan_id: "plan_FyQok2sNObIWU7",
          prod_id: "prod_F0ymHDps8vXC8E",
          name: "Business",
          type: "BC",
          freq: "Monthly",
          price: 22.99,
        },
        {
          plan_id: "plan_GzkE60cxBcrRtR",
          prod_id: "prod_F0ymHDps8vXC8E",
          name: "Business",
          type: "BC",
          freq: "Yearly",
          price: 261,
        },
        {
          plan_id: "plan_GzkEXIQzYLTwbW",
          prod_id: "prod_F0ymHDps8vXC8E",
          name: "Business",
          type: "BC",
          freq: "Monthly",
          price: 29,
        },
        {
          plan_id: "plan_GIiQzv91KMFoc7",
          prod_id: "prod_GIiQ7vNeWruPdm",
          name: "Professional",
          type: "Loyalty",
          freq: "Yearly",
          price: 356.9,
        },
        {
          plan_id: "plan_GIiQG77nc2W1gr",
          prod_id: "prod_GIiQ7vNeWruPdm",
          name: "Professional",
          type: "Loyalty",
          freq: "Monthly",
          price: 34.99,
        },
        {
          plan_id: "plan_FwGPEb9VpXQxKQ",
          prod_id: "prod_FwGOHohlnPvVdb",
          name: "Professional",
          type: "Loyalty",
          freq: "Monthly",
          price: 34.99,
        },
        {
          plan_id: "plan_GIiBYEwloUN5Fh",
          prod_id: "prod_FwGOHohlnPvVdb",
          name: "Professional",
          type: "Loyalty",
          freq: "Monthly",
          price: 49.99,
        },
        {
          plan_id: "plan_FwGRvmBFEi4Fzr",
          prod_id: "prod_FwGQlWefa5MBuT",
          name: "Professional",
          type: "Loyalty",
          freq: "Monthly",
          price: 49.99,
        },
        {
          plan_id: "price_1H5H6SES9JaRReUPqIt5bDP7",
          prod_id: "prod_HeVwvAnrmWzCmH",
          name: "Enterprise",
          type: "BC",
          freq: "Yearly",
          price: 20.4,
        },
        {
          plan_id: "price_1H5CudES9JaRReUPpU881nSm",
          prod_id: "prod_HeVwvAnrmWzCmH",
          name: "Enterprise",
          type: "BC",
          freq: "Yearly",
          price: 20.4,
        },
        {
          plan_id: "price_1H5CzZES9JaRReUPwSCbxOdN",
          prod_id: "prod_HeVwvAnrmWzCmH",
          name: "Enterprise",
          type: "BC",
          freq: "Yearly",
          price: 20.4,
        },

        //Nuevos
        {
          plan_id: "price_1HEZrVES9JaRReUP6c2KWu4z",
          prod_id: "prod_HYa7rY0AqBdROa",
          name: "Professional",
          type: "MP",
          freq: "Monthly",
          price: 25,
        },
        {
          plan_id: "price_1HEZsbES9JaRReUPQ08mc0s8",
          prod_id: "prod_HYa7rY0AqBdROa",
          name: "Professional",
          type: "MP",
          freq: "Yearly",
          price: 225,
        },
        {
          plan_id: "price_1HEZqLES9JaRReUPsiyWfomM",
          prod_id: "prod_HL9eOnCcoDm6Tm",
          name: "Business",
          type: "MP",
          freq: "Monthly",
          price: 92,
        },
        {
          plan_id: "price_1HEZqdES9JaRReUPVcDJb3fI",
          prod_id: "prod_HL9eOnCcoDm6Tm",
          name: "Business",
          type: "MP",
          freq: "Yearly",
          price: 828,
        },

        //2021

        {
          plan_id: "pro_2021_m",
          prod_id: "prod_HYa7rY0AqBdROa",
          name: "Professional",
          type: "MP",
          freq: "Monthly",
          price: 5,
        },
        {
          plan_id: "pro_2021_y",
          prod_id: "prod_HYa7rY0AqBdROa",
          name: "Professional",
          type: "MP",
          freq: "Yearly",
          price: 50,
        },
        {
          plan_id: "bus_2021_m",
          prod_id: "prod_HL9eOnCcoDm6Tm",
          name: "Business",
          type: "MP",
          freq: "Monthly",
          price: 25,
        },
        {
          plan_id: "bus_2021_y",
          prod_id: "prod_HL9eOnCcoDm6Tm",
          name: "Business",
          type: "MP",
          freq: "Yearly",
          price: 225,
        },
        //2023

        {
          plan_id: "pro_2023_m",
          prod_id: "prod_HYa7rY0AqBdROa",
          name: "Professional",
          type: "MP",
          freq: "Monthly",
          price: 6,
        },
        {
          plan_id: "pro_2023_y",
          prod_id: "prod_HYa7rY0AqBdROa",
          name: "Professional",
          type: "MP",
          freq: "Yearly",
          price: 54,
        },
        {
          plan_id: "bus_2023_m",
          prod_id: "prod_HYa7rY0AqBdROa",
          name: "Business",
          type: "MP",
          freq: "Monthly",
          price: 80,
        },
        {
          plan_id: "bus_2023_y",
          prod_id: "prod_HYa7rY0AqBdROa",
          name: "Business",
          type: "MP",
          freq: "Yearly",
          price: 720,
        },
      ];

      /*
            let elprod=null
            for(let i_prod in planes_businesscards){
              let prodd=planes_businesscards[i_prod]
              for(let i_plan in prodd.plans){
                let pllan=prodd.plans[i_plan]
                if(pllan && lasub && pllan.plan_id==lasub.plan_id){
                  elprod=prodd
                }
              }
            } */

      let elprod = old_plans.find((e) => {
        return e.plan_id == lasub.plan_id;
      });
      if (!elprod) {
        return "none";
      } else {
        return elprod;
      }
    },
    hasAllLeadBooster(profile = store.state.userProfile) {
      if (!profile) return false;
      var lincs = profile.linckards;
      let tiene = true;
      lincs.forEach((u) => {
        if (!u.leadbooster) tiene = false;
      });
      return tiene;
    },
    hasLeadBooster(profile = store.state.userProfile) {
      if (!profile) return false;
      var lincs = profile.linckards;
      let tiene = false;
      lincs.forEach((u) => {
        if (u.leadbooster) tiene = true;
      });
      return tiene;
    },
    detectProduct() {
      if (this.isCustomizer() && store.state.editor.active.isLanding)
        return "mp";
      else if (this.isCustomizer() && !store.state.editor.active.isLanding)
        return "bc";
      else if (router.history.current.name.includes("landing")) return "mp";
      else if (router.history.current.name.includes("linkcard")) return "bc";
      else if (router.history.current.name.includes("signature")) return "bc";
      else if (store.state.mode) return store.state.mode;
      else return "general";
    },
    detectSource() {
      if (router.history.current.name.includes("editor")) return "editor";
      else if (router.history.current.name.includes("sector")) return "sector";
      else if (router.history.current.name.includes("stats")) return "stats";
      else if (router.history.current.name.includes("leads")) return "leads";
      else if (router.history.current.name.includes("signature"))
        return "signature";
      else if (router.history.current.name.includes("account"))
        return "account";
      else return "general";
    },
    registerStat(type, objeto) {
      let profile = store.state.userProfile;
      try {
        console.log("stat:", type);
        let statobj = {
          type: type,
          source: this.detectSource(),
          product: this.detectProduct(),
          locale: this.getLocale(),
          date: new Date().toISOString(),
          date_time: new Date().getTime(),
          device: this.isMobile() ? "mobile" : "desktop",
          browser: this.detectBrowser(),
          os: this.isAndroid() ? "android" : this.isApple() ? "ios" : "windows",
          from: {
            name: profile.account_details.account_name,
            uid: profile.uid, //para firestore
            email: profile.email, //para firestore
            cant_linkcards: profile.stats.cant_linkcards,
            cant_linkcards_active: profile.stats.cant_linkcards_active,
          },
        };
        if (type == "signup")
          statobj.provider = profile.account_details.signup_provider;

        statobj = { ...statobj, ...objeto };

        //Stat
        db.collection("stats").add(statobj);
        let segment = this.parseSegment();
        //console.log('segment',segment)
        if (this.isFounder()) return;

        if (mixpanel) {
          if (type == "login") mixpanel.identify(profile.uid);
          else if (type == "signup") mixpanel.alias(profile.uid);
          let mxiObj = {
            USER_ID: profile.uid, // use human-readable names
            $email: profile.email, // only reserved properties need the $
            $name: profile.account_details.account_name, // only reserved properties need the $
            account_date: profile.account_details.account_date,
            account_country: profile.account_details.account_country,
            account_state: profile.account_details.account_state,
            account_origin: profile.account_details.account_origin,
            account_locale: profile.account_details.account_locale,
            signup_provider: profile.account_details.signup_provider,
            cant_linkcards_active: profile.stats.cant_linkcards_active,
            segment_name: segment ? segment.name : "",
            segment_freq: segment ? segment.freq : "",
            company_size: profile.account_details.company_size,
            c_linckards: profile.stats.c_linckards,
            c_sectors: profile.stats.c_sectors,
          };
          mixpanel.people.set(mxiObj);
          mixpanel.track(type, statobj);
          console.log("mixpanel", mxiObj, type);
        }
        if (gtm) {
          console.log(
            "gtm:userId:(" +
              type +
              ")" +
              profile.uid +
              ":" +
              segment.name +
              ":" +
              statobj.locale
          );
          gtm.push({
            event: type,
            userId: profile.uid,
            dimension1: segment.name,
            locale: statobj.locale,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    getStatsDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var date = yyyy + "-" + mm + "-" + dd;
      return date;
    },
    paddy(num, padlen, padchar) {
      if (!num) return "00000";
      var pad_char = typeof padchar !== "undefined" ? padchar : "0";
      var pad = new Array(1 + padlen).join(pad_char);
      return (pad + num).slice(-pad.length);
    },
    generateCSS(ppreview) {
      if (!ppreview) return;
      const style_model = JSON.parse(JSON.stringify(linckard_model.style));
      if (ppreview.membre)
        style_model.membre = JSON.parse(JSON.stringify(loyalty_style));
      //membresia
      const preview = JSON.parse(JSON.stringify(ppreview));
      var parse_obj = "{";
      var keys = Object.keys(style_model);
      keys.forEach((key) => {
        var hijo = style_model[key];
        var keyss = Object.keys(hijo);
        keyss.forEach((keyy) => {
          var hijo = style_model[key][keyy];
          var keysss = Object.keys(hijo);
          keysss.forEach((keyyy) => {
            if (
              !preview[key] ||
              !preview[key][keyy] ||
              !preview[key][keyy][keyyy]
            ) {
              parse_obj +=
                '"--' +
                key +
                "-" +
                keyy +
                "-" +
                keyyy +
                '":"' +
                style_model[key][keyy][keyyy] +
                '",';
            } else {
              //if(keyyy=='family')preview[key][keyy][keyyy]=preview[key][keyy][keyyy].replace(' ','+')//fix font spaces
              if (
                keyyy == "family" &&
                preview[key][keyy][keyyy] == "Fira Sans Extra Condensed"
              )
                preview[key][keyy][keyyy] = "Fira Sans";

              if (keyyy == "family")
                parse_obj +=
                  '"--' +
                  key +
                  "-" +
                  keyy +
                  "-" +
                  keyyy +
                  '":"\'' +
                  preview[key][keyy][keyyy] +
                  "'\",";
              else
                parse_obj +=
                  '"--' +
                  key +
                  "-" +
                  keyy +
                  "-" +
                  keyyy +
                  '":"' +
                  preview[key][keyy][keyyy] +
                  '",';
            }
          });
        });
      });
      if (preview["sobre"]["fondo"]["img"])
        parse_obj +=
          '"--fondo-img":"url(\'' + preview["sobre"]["fondo"]["img"] + "')\";";

      var char_pos = parse_obj.length - 1;
      var part1 = parse_obj.substring(0, char_pos);
      var part2 = parse_obj.substring(char_pos + 1, parse_obj.length);
      parse_obj = part1 + part2 + "}";
      parse_obj = JSON.parse(parse_obj);
      return parse_obj;
    },
    getLandingBackImage(preview, size = "1500x1500") {
      let img =
        preview.style && preview.style.sobre
          ? preview.style.sobre.fondo.img
          : null;
      //if(!img)return require('@/assets/images/Iso_background_card_template.svg')
      if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img, size);
    },

    getBackImage(preview, size = "1500x1500") {
      let img = preview.style.info
        ? preview.style.info.fondo.img
        : preview.style.membre.fondo.img;
      if (!img) {
        if (preview.event_id >= 0)
          return require("@/assets/images/iso_background_card.svg");
        else if (preview.loyalty_id >= 0)
          return require("@/assets/images/Iso_background_card_loyalty.svg");
        else return require("@/assets/images/tarjeta_bg_logo.svg");
      }
      if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img, size);
    },

    getSobreBackImage(preview, size = "1500x1500") {
      let img =
        preview.style && preview.style.sobre
          ? preview.style.sobre.fondo.img
          : null;
      if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img, size);
    },
    getBackMembreImage(preview, size = "1500x1500") {
      let img = preview.style.membre
        ? preview.style.membre.fondo.img
        : preview.style.info.fondo.img;
      if (!img) {
        if (preview.event_id >= 0)
          return require("@/assets/images/iso_background_card.svg");
        else if (preview.loyalty_id >= 0)
          return require("@/assets/images/Iso_background_card_loyalty.svg");
        else return require("@/assets/images/tarjeta_bg_logo.svg");
      }
      if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img, size);
    },

    getSobreImagen(img, size = "336x336") {
      if (img && typeof img === "string" && img.includes("?usp=share_link"))
        img = img.replace("?usp=share_link", "");
      if (!img) return require("@/assets/images/tarjeta_image_preview.png");
      else if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img, size);
    },

    getFooterImgMember(linckard, size = "336x336") {
      if (!linckard) return null;
      let img =
        linckard.style.membre &&
        linckard.style.membre.caja_logo &&
        linckard.style.membre.caja_logo.img
          ? linckard.style.membre.caja_logo.img
          : linckard.style.info.caja_logo.img;
      if (!img) return require("@/assets/images/Your_Logo_Here.png");
      else if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img, size);
      return null;
    },

    getFooterImg(linckard, size = "336x336") {
      if (!linckard) return null;
      let img = linckard.style.info.caja_logo.img;
      if (!img) return require("@/assets/images/Your_Logo_Here.png");
      else if (img && img.obj) return img.obj;
      else if (img) return this.removeToken(img, size);
      return null;
    },
    removeToken(img, size) {
      return helpers.removeToken(img, size);
    },
    /*PRO METHODSSSSS*/
    /*PRO METHODSSSSS*/
    /*PRO METHODSSSSS*/
    /*PRO METHODSSSSS*/
    /*PRO METHODSSSSS*/
    initTrial(profile, type) {
      if (!profile.trial_inits) {
        profile.trial_inits = {};
        profile.trial_inits["general"] = this.getDate();
        profile.trial_inits["general" + "_time"] = new Date().getTime();
      }
      if (profile.trial_inits[type]) return; //ya fue iniciado
      profile.trial_inits[type] = this.getDate();
      profile.trial_inits[type + "_time"] = new Date().getTime();

      db.collection("users")
        .doc(profile.uid)
        .update({ trial_inits: profile.trial_inits });
    },
    parseBirthday(year, month, day) {
      if (!year) year = "yyyy";
      return year + "/" + month + "/" + day;
    },
    getMode() {
      return store.state.mode;
    },
    isDemos(profile = store.state.userProfile) {
      return (
        profile &&
        (profile.email == "demos@linkcard.app" ||
          profile.email == "demos1@linkcard.app" ||
          profile.email == "demos2@linkcard.app" ||
          profile.email == "demos3@linkcard.app" ||
          profile.email == "demos4@linkcard.app")
      );
    },
    isFabri(profile = store.state.userProfile) {
      return profile && profile.email == "fguespe@gmail.com";
    },
    isEnterprise(profile = store.state.userProfile) {
      if (profile.email == "daiana.bossana@sancorsalud.com.ar") return true;
      if (profile.email == "cecilia.rossi@sancorsalud.com.ar") return true;
      if (profile.email == "nordec-it@nordec.com") return true;
      if (profile.isEnterprise)return true

      //if(this.isFabri(profile))return true
      return false;
    },
    isTesting(profile = store.state.userProfile) {
      /* return (
        this.isLocalhost() ||
        process.env.AMBIENTE == "development" ||
        (profile && profile.email == "fguespe@gmail.com")
      ); */
      return false
    },
    isLocalhost() {
      if (process.client) {
        if (window.location.hostname === "localhost") return true;
      }
      return false;
    },
    isManualPro(profile) {
      if (!profile) return false;
      else if (this.isFounder(profile)) return true;
      else if (profile.isManualPro && profile.isManualPro.date) {
        const date1 = new Date();
        const date2 = new Date(profile.isManualPro.date);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        console.log(diffDays + " days");
        if (diffDays <= 30) return true;
      } else if (profile.isManualPro) return true;
      else return false;
    },
    isFounder(profile = store.state.userProfile) {
      if (!profile || !profile.email) return false;
      if (this.isTesting()) return true;
      //if(profile.email.includes('fguespe') && this.isTesting())return true;

      if (this.isDemos()) return true;
      if (profile.email.includes("inlat")) return true;
      if (profile.email.includes("efex")) return true;
      if (profile.email.includes("fafa.com")) return true;
      if (profile.email.includes("grosfeld")) return true;
      if (profile.email == "fabrizio@linckard.com") return true;
      if (profile.email == "fabrizio@linkcard.app") return true;
      if (profile.email == "f@altoweb.co") return true;
      if (profile.email == "roni@inlat.biz") return true;
      if (profile.email == "roni@linkcard.app") return true;
      if (profile.isFounder) return true;
      if (profile.stripe_details?.stripe_plan?.founder) return true;
      return false;
    },
    isPro(profile = store.state.userProfile) {
      if (this.isManualPro(profile)) return true;
      if (this.isEnterprise(profile)) return true;
      if (this.hasOnePremiumPlan(profile, "bc")) return true;
      return false;
    } /*
        isTrialInit(profile,type){
          if(this.isManualPro(profile))return true
          if(this.hasOnePremiumPlan(profile,type))return true
          if(profile.trial_inits && profile.trial_inits[type])return true
          return false
        },*/,
    isInTrial(profile = store.state.userProfile, type) {
      return false;
      if (this.isPro(profile)) return false;
      if (!profile || !profile.account_details) return false;
      if (profile.trial_inits && profile.trial_inits[type])
        if (this.diffDays(profile.trial_inits[type]) <= 15) return true;
      if (this.diffDays(profile.account_details.account_date) <= 15)
        return true;
      return false;
    },
    isTrialOrPro(modal, place, force = false, type = "bc") {
      let profile = store.state.userProfile;
      if (this.isManualPro(profile)) return true;
      if (
        this.isInTrial(profile, type) ||
        this.hasOnePremiumPlan(profile, type) ||
        (this.isLoyaltyCustomizer() &&
          this.hasOnePremiumPlan(profile, "loyalty"))
      )
      return true;

      if (modal) {
        this.registerStat("payment", { subtype: "popup", place: place });
        modal.show("modal-planes", {
          place: place,
          user: profile,
          planes: stripePlans,
          close: force,
        });
      } else {
        return false;
      }
    },
    isProLoyaltyanalytics(modal, profile) {
      return true;
    },
    getNombreMes(mes) {
      return i18n.t("meses." + mes);
    },
    isBC() {
      return this.isMODE_ON("bc");
    },
    DASHHOME_url(type = store.state.mode) {
      let url = "";
      console.log("entra al dash", type);
      if (type == "loyalty") {
        url = "/loyalty/loyaltys";
      } else if (type == "memberships") {
        url = "/loyalty/memberships";
      } else {
        console.log("this.showSectors(true)", this.showSectors(true));
        if (this.showSectors(true)) url = "/sector/sectors/bc";
        else if (
          (store.state.userProfile.sectors[1] &&
            store.state.userProfile.sectors[1].isLanding &&
            store.state.userProfile.sectors[1].id == 1) ||
          (store.state.userProfile.sectors[0] && store.state.userProfile.sectors[0].id == 1)  /* && store.state.userProfile.sectors[1].name=='Default'*/
        )
          url = "/sector/1/landings";
        else url = "/sector/0/landings";
      }
      return url;
    },
    gotoHOME_url(onboarding) {
      onboarding  = false
      let url = "/user";
      console.log(
        route.path,
        store.state.mode,
        store.state.editor.active,
        router.history.current.name
      );
      if (
        route.path &&
        route.path != "/" &&
        (route.path.includes("stat") ||
          route.path.includes("lead") ||
          route.path.includes("sector"))
      )
        url = route.path;
      else if (onboarding && store.state.mode == "mp")
        url = "/onboarding/landing/?sector_id=0#1";
      else if (onboarding && store.state.mode == "bc")
        url = "/onboarding/linkcard/?sector_id=0#1";
      else if (onboarding) url = "/onboarding/landing/?sector_id=1#1";
      else if (
        router.history.current.name.includes("editor") &&
        store.state.editor &&
        store.state.editor.active &&
        store.state.editor.active.isLanding
      )
        url = this.DASHHOME_url();
      //else if(router.history.current.name.includes('editor') && store.state.editor && store.state.editor.active && !store.state.editor.active.isLanding)url=this.DASHHOME_url('bc')
      //else if(router.history.current.name.includes('linkcard'))url=this.DASHHOME_url('bc')
      else if (router.history.current.name.includes("landing"))
        url = this.DASHHOME_url();
      else if (this.getLandingPages().length > 0) url = this.DASHHOME_url();
      //else if(this.getBusinessCards().length>0)url= this.DASHHOME_url('bc')
      else url = this.DASHHOME_url();
      return '/dashboard';
    },
    gotoHOME(onboarding = false) {
      router.push(this.gotoHOME_url(onboarding));
    },
    isMODE_ON(type = this.getMode()) {
      return true;
      //if(this.getMode()==type)return true
    },
    isMODE_ANY() {
      return true;
    },
    /*CREATES MORE*/
    /*CREATES MORE*/
    /*CREATES MORE*/
    /*CREATES MORE*/
    /*CREATES MORE*/
    /*CREATES MORE*/
    /*CREATES MORE*/
    isProLoyaltyAgents(modal, profile, objMain) {
      return true; /*
          const cant=objMain.agents.length;
          const planes=planes_loyalty
          var actual=this.getCurrentSubscription(profile,"loyalty").prod_id
          if(profile.uid=='znH7IqhFIuWIYH03EIy8BEHMgtD3' && cant <9)return true//entredos
          if(cant<1 && (this.isInTrial(profile,"loyalty") || this.isManualPro(profile)))return true
          else if(!this.hasOnePremiumPlan(profile,'loyalty'))modal.show('modal-planes',{place:'loyalty',user:profile,planes:planes})//Freemium
          else if(actual.localeCompare(planes[1].prod_id)==0 && cant>=planes[1].max_agents)modal.show('modal-planes',{place:'loyalty',user:profile,planes:planes})//Freemium
          else if(actual.localeCompare(planes[2].prod_id)==0 && cant>=planes[2].max_agents)modal.show('modal-planes',{place:'loyalty',user:profile,planes:planes})
          else if(actual.localeCompare(planes[3].prod_id)==0 && cant>=planes[3].max_agents)modal.show('modal-planes',{place:'loyalty',user:profile,planes:planes})
          else if(actual.localeCompare(planes[4].prod_id)==0 && cant>=planes[4].max_agents)modal.show('modal-planes',{place:'loyalty',user:profile,planes:planes})
          else return true;*/
    },
    isProLoyaltys(modal, profile, type) {
      if (this.isManualPro(profile)) return true;
      const cant = profile.loyaltys.length;
      const planes = planes_businesscards;
      let sub_actual = this.getCurrentSubscription(profile, "bc");
      var actual = sub_actual.prod_id;
      if (cant < 1) return true;
      else if (!this.hasOnePremiumPlan(profile, "bc"))
        modal.show("modal-planes", {
          place: type ? type : "linkcard",
          user: profile,
          planes: stripePlans,
        });
      else if (
        actual.localeCompare(planes[1].prod_id) == 0 &&
        cant >= planes[1].max_linkcards
      )
        modal.show("modal-planes", {
          place: type ? type : "linkcard",
          user: profile,
          planes: stripePlans,
        });
      else if (
        actual.localeCompare(planes[2].prod_id) == 0 &&
        cant >= planes[2].max_linkcards
      )
        modal.show("modal-planes", {
          place: type ? type : "linkcard",
          user: profile,
          planes: stripePlans,
        });
      else if (
        this.hasOnePremiumPlan(profile, "bc") &&
        cant < planes[1].max_linkcards
      )
        return true;
      //viejos normalizados a pro
      else if (
        actual.localeCompare("prod_F0ymHDps8vXC8E") == 0 &&
        cant < planes[2].max_linkcards
      )
        return true;
      //viejos normalizados a pro
      else if (
        actual.localeCompare("prod_HeVwvAnrmWzCmH") == 0 &&
        cant < sub_actual.max_linkcards
      )
        return true;
      //ENTERPRISE con max_linkcards
      else
        modal.show("modal-planes", {
          place: type ? type : "linkcard",
          user: profile,
          planes: stripePlans,
        });
    },
    showProLandings(modal, profile, type, callback) {
      modal.show("modal-planes", {
        place: type ? type : "linkcard",
        user: profile,
        planes: stripePlans,
      });
    },
    isProLandings(modal, profile, type, callback) {
      if(profile.stripe_details.stripe_plan && profile.stripe_details.stripe_plan){
        profile = {...profile, ...profile.stripe_details.stripe_plan}
      }
      // Criterios de NO control
      if (this.isManualPro(profile)) return true;
      // Cantidad de linkcards:
      let cant = 0// .length + (profile.linckards?.loyaltys?.length ?? 0);

      profile.linckards.filter(u => { return !["deleted"].includes(u.status)}).map( lc => {
        let sector = profile.sectors.find(s => s.id == lc.sector_id)
        let sectortype = sector.type ? sector.type : 'bc'
        if(sectortype === type){
          cant++
        }else{
        }
      })
      const max = profile.stripe_details.stripe_plan['max_linkcards_' + type] ? parseFloat(profile.stripe_details.stripe_plan['max_linkcards_' + type]) : 3;
      // Checkeo propiamente dicho
      
      console.log('max_linkcards_' + type, cant,max)
      if(cant < max){
        return true;
      } else {
        const planes = stripePlans;
        if (typeof callback === 'function') {
          callback(() => {
            modal.show("modal-planes", {
              place: type ? type : "linkcard",
              user: profile,
              planes: planes,
            });
          });
        } else {
          modal.show("modal-planes", {
            place: type ? type : "linkcard",
            user: profile,
            planes: planes,
          });
        }
      }
    },
    isProLandingsActive(modal, profile) {
      if (this.isManualPro(profile)) return true;
      const cant = this.getActiveLandings().length;
      if (cant < 1) return true;
      console.log(planes);
      const planes = planes_businesscards;
      let sub_actual = this.getCurrentSubscription(profile, "bc"); //bc es mp
      if (!sub_actual) return false;
      console.log(cant, parseInt(sub_actual.max_linkcards));
      if (
        sub_actual.max_linkcards != undefined &&
        cant <= parseInt(sub_actual.max_linkcards)
      )
        return true;
      if (
        sub_actual.prod_id.localeCompare(planes[1].prod_id) == 0 &&
        cant < planes[1].max_linkcards
      )
        return true;
      else if (
        sub_actual.prod_id.localeCompare(planes[2].prod_id) == 0 &&
        cant < planes[2].max_linkcards
      )
        return true;
      else if (
        this.hasOnePremiumPlan(profile, "bc") &&
        cant < planes[1].max_linkcards
      )
        return true;
      //viejos normalizados a pro
      else if (
        (sub_actual.plan_id.localeCompare("price_1HEZrVES9JaRReUP6c2KWu4z") ==
          0 ||
          sub_actual.plan_id.localeCompare("price_1HEZsbES9JaRReUPQ08mc0s8") ==
            0) &&
        cant < 10
      )
        return true;
      //professional con bc 10
      else if (
        sub_actual.prod_id.localeCompare("prod_F0ymHDps8vXC8E") == 0 &&
        cant < planes[2].max_linkcards
      )
        return true;
      //viejos normalizados a pro
      else if (
        sub_actual.prod_id.localeCompare("prod_LvLdrTlc0D3cOi") == 0 &&
        cant < planes[1].max_linkcards
      )
        return true;
      //partners lifetime max 3 cards
      else if (
        sub_actual.prod_id.localeCompare("prod_MZBA9vBN2Zi6NX") == 0 &&
        cant < planes[2].max_linkcards
      )
        return true;
      //partners lifetime max 40 cards
      else if (
        sub_actual.prod_id.localeCompare("prod_HeVwvAnrmWzCmH") == 0 &&
        cant < sub_actual.max_linkcards
      )
        return true;
      //ENTERPRISE con max_linkcards
      else
        modal.show("modal-planes", {
          place: "linkcard",
          user: profile,
          planes: planes,
        });
    },

    isProNetworking(modal, profile) {
      const planes = planes_businesscards;
      if (
        this.isInTrial(profile, "bc") ||
        this.isManualPro(profile) ||
        this.hasOnePremiumPlan(profile, "bc")
      )
        return true;
      else
        modal.show("modal-dirinit", {
          place: "dir_init",
          user: profile,
          planes: planes,
        });
    },
    isProElementosSobreModal(modal, profile, element, type) {
      if (element && element.tipo == "freemium") return true;
      if (!profile) return false;
      if (
        !(
          this.hasOnePremiumPlan(profile, "bc") ||
          this.isManualPro(profile) ||
          this.isLoyaltyCustomizer()
        )
      ) {
        modal.show("modal-planes", {
          place: type ? type : 'bc',
          user: profile,
          planes: stripePlans,
        });
        return false;
      }
      return true;
    },
    isProElementosSobre(profile, element) {
      if (element && element.tipo == "freemium") return true;
      if (!profile) return false;
      if (
        this.hasOnePremiumPlan(profile, "bc") ||
        this.isManualPro(profile) ||
        this.isLoyaltyCustomizer()
      )
        return true;
      return false;
    },
    isProBcanalytics(profile) {
      return (
        this.isInTrial(profile, "bc") ||
        this.hasOnePremiumPlan(profile, "bc") ||
        this.isManualPro(profile)
      );
    },
  });
};

export const stripePlans = {
  bc: { 
    monthly: 'price_1PxEcNES9JaRReUPpDSGWVDr', 
    yearly: 'price_1PxEdHES9JaRReUPN9XhKM7r'
  },
  lib: { 
    monthly: 'price_1PsNxCES9JaRReUP4CPbQMpG', 
    yearly: 'price_1PsNyIES9JaRReUPph3FAT3C'
  },
  pas: { 
    monthly: 'price_1PsNsEES9JaRReUPQH5dgyLe', 
    yearly: 'price_1PsNtdES9JaRReUPDYn4pUTo'
  },
  loyaltys: { 
    monthly: 'price_1PsO8ZES9JaRReUPzrjFBoYy', 
    yearly: 'price_1PsO9cES9JaRReUPGWhZ4QZu'
  },
  
  evnt: { 
    monthly: 'price_1PsO4bES9JaRReUPZHw5E3Gb', 
    yearly: 'price_1PsNtdES9JaRReUPDYn4pUTo'
  },
  leads: { 
    monthly: 'price_1Pqh3vES9JaRReUP21ymngyl', 
    yearly: 'price_1Pqh3vES9JaRReUP21ymngyl'
  },
  stats: { 
    monthly: 'price_1Pqh3vES9JaRReUP21ymngyl', 
    yearly: 'price_1Pqh3vES9JaRReUP21ymngyl'
  },
};